import {ESupportProfileTabs} from '../definitions';
import {EConfigsTab} from './ConfigsTab/definitions';

export default {
  availableTariffs: 'Доступные тарифы',
  title: 'Карточка клиента',
  licenseStatus: 'Статус лицензии',
  expiredAt: 'до ',
  responsibleManager: 'Ответственный менеджер edna',
  partner: 'Агент',
  taxNumber: 'ИНН',
  country: 'Страна',
  currency: 'Валюта',
  reseller: 'Юрисдикция',
  contact: 'Контактное лицо клиента',
  contactName: 'ФИО',
  phone: 'Телефон',
  companyName: 'Название организации',
  companyData: 'Данные компании',
  tariffs: 'Тарифы',
  empty: 'Не указано',
  responsibleManagerModal: {
    title: 'Изменить ответственного менеджера',
    selectManager: 'Выберите ответственного менеджера',
  },
  tabs: {
    [ESupportProfileTabs.PAYMENT]: 'Оплата',
    [ESupportProfileTabs.REQUISITES]: 'Договор и реквизиты',
    [ESupportProfileTabs.PRICING]: 'Тарифы',
    [ESupportProfileTabs.CONFIGS]: 'Конфигурации',
    [ESupportProfileTabs.LICENSE]: 'Лицензии',
  },
  configsTabs: {
    [EConfigsTab.CALLBACK]: 'API и Callback',
    [EConfigsTab.SETTINGS]: 'Прочие настройки',
  },
};
