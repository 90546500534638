import {createSlice} from '@reduxjs/toolkit';

import {storage} from '@edna/utils';

import {EStorageKeys} from 'src/constants';
import {listenerMiddleware} from 'src/models';

import {getCurrentUser} from './api';
import {EPermission, ERoles} from './definitions';

export type TState = {
  tenantId: number | null;
  permissions: EPermission[];
  roles: ERoles[];
};

const initialState = (): TState => {
  const tenantId = Number(storage.get(EStorageKeys.TENANT_ID)) || null;

  return {tenantId, permissions: [], roles: []};
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTenantId(state, {payload}) {
      state.tenantId = payload;
    },
    resetTenant() {
      return {
        tenantId: null,
        permissions: [],
        roles: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getCurrentUser.matchFulfilled, (state, {payload}) => {
      state.permissions = payload.permissions;
      state.roles = payload.roles;

      if (!payload.tenants.length) {
        state.tenantId = null;

        return;
      }

      if (state.tenantId === null || !payload.tenants.some(({id}) => id === state.tenantId)) {
        state.tenantId = payload.tenants[0].id;
      }
    });
  },
});

const {setTenantId, resetTenant} = slice.actions;
const selectTenantId = (state: TRootState) => state.user.tenantId;

listenerMiddleware.startListening({
  predicate: (_, currentState, previousState) =>
    currentState.user.tenantId !== previousState.user.tenantId && !!currentState.user.tenantId,
  effect: (_, listenerApi) =>
    storage.set(EStorageKeys.TENANT_ID, listenerApi.getState().user.tenantId),
});

const userSlice = slice.reducer;

export {userSlice, selectTenantId, setTenantId, resetTenant};
