import {storage} from '@edna/utils';

import {EStorageKeys} from 'src/constants';
import {rootApi} from 'src/models';

import {resetTenant} from './slice';

export const resetUser = () => (dispatch: TDispatch) => {
  sessionStorage.clear();
  storage.remove(EStorageKeys.NEWS);
  dispatch(resetTenant());
  dispatch(rootApi.util.resetApiState());
};
