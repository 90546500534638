import {isAllOf} from '@reduxjs/toolkit';

import {TCurrency, buildQueryString, not, storage} from '@edna/utils';

import {EStorageKeys} from 'src/constants';
import {
  ECacheTag,
  errorNotification,
  isMatchQueryMeta,
  listenerMiddleware,
  rootApi,
} from 'src/models';

import {
  EFeatureFlag,
  EResellerIds,
  ERoles,
  TBankDetails,
  TPaymentType,
  TTenant,
  TTenantUser,
  TUser,
} from './definitions';

const api = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<TUser, void>({
      query: () => ({
        method: 'GET',
        url: '/tenantmanagement/user/current',
      }),
      providesTags: [ECacheTag.USER],
    }),
    getTenantUsers: builder.query<TTenantUser[], void>({
      query: () => ({
        method: 'GET',
        url: '/tenantmanagement/user/list-logins',
        meta: {isShowError: true, roles: not<ERoles>('ROLE_BASIC_USER')},
      }),
    }),
    getTenantData: builder.query<TTenant, void>({
      query: () => ({
        method: 'GET',
        url: '/tenantmanagement/tenant/data',
        meta: {isShowError: true},
      }),
    }),
    getBankDetails: builder.query<
      TBankDetails[],
      {currencyCode?: TCurrency; paymentType?: TPaymentType; resellerId: EResellerIds}
    >({
      query: ({currencyCode, resellerId, paymentType}) => ({
        method: 'GET',
        url: currencyCode
          ? `/tenantmanagement/bank-details/find${buildQueryString({
              currency: currencyCode,
              resellerId,
              paymentType,
            })}`
          : `/tenantmanagement/bank-details/by-reseller-id/${resellerId}`,
        meta: {isShowError: true},
      }),
    }),
    setUserTimeZone: builder.mutation<void, string>({
      query: (timeZone) => ({
        method: 'PUT',
        url: '/tenantmanagement/user/time-zone',
        data: {timeZone},
      }),
      invalidatesTags: [ECacheTag.USER],
    }),
    getFeatureFlags: builder.query<Partial<Record<EFeatureFlag, boolean>>, void>({
      query: () => ({
        method: 'GET',
        url: `/tenantmanagement/unleash/feature-toggle-list/${Number(storage.get(EStorageKeys.TENANT_ID))}`,
        meta: {isShowError: true},
      }),
    }),
  }),
});

listenerMiddleware.startListening({
  matcher: isAllOf(
    api.endpoints.getCurrentUser.matchRejected,
    isMatchQueryMeta({isBackendError: true}),
  ),
  effect: ({payload}) => {
    if (payload.status !== 401) {
      errorNotification(payload);
    }
  },
});

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetTenantDataQuery,
  useLazyGetTenantDataQuery,
  useGetBankDetailsQuery,
  useSetUserTimeZoneMutation,
  useGetFeatureFlagsQuery,
  useGetTenantUsersQuery,
  endpoints: {getTenantData, getCurrentUser},
} = api;
