import {TCurrency} from '@edna/utils';

import {TItemId} from 'src/constants';

export type TBalance = {
  maxOverdraft: number;
  lock: boolean;
  updatedAt: string;
  createdAt: string;
  tenantId?: TItemId;
  amount?: number;
  currency?: TCurrency;
};

export type TBalanceModalPayload = {
  amount: number;
  operationDescription: string;
  createdAt: Date;
  supportTenantId: TBalance['tenantId'];
};

export type TBalanceModalFormData = Omit<TBalanceModalPayload, 'supportTenantId'>;

export type TOverdraftHistory = {
  createdAt: string;
  currency: TCurrency;
  id: number;
  newMaxOverdraft: number;
  oldMaxOverdraft: number;
  tenantId: TItemId;
  userFullName: string;
};

export type TChangeOverdraftFormData = {
  amount: number;
};

export enum EDocumentType {
  ALL = 'ALL',
  PAYMENT_BILL = 'PAYMENT_BILL',
  LICENSE_BILL = 'LICENSE_BILL',
  INVOICE = 'INVOICE',
  ACCOUNTING_BILL = 'ACCOUNTING_BILL',
  ACCEPTANCE_ACT = 'ACCEPTANCE_ACT',
  OTHER = 'OTHER',
}

export type TDocumentType = keyof typeof EDocumentType;

export enum EDocumentParam {
  CONTRACT_NUMBER = 'CONTRACT_NUMBER',
  CONTRACT_MONTH = 'CONTRACT_MONTH',
  CONTRACT_YEAR = 'CONTRACT_YEAR',
}

export type TDocumentParam = keyof typeof EDocumentParam;

export type TDocument = {
  id: number;
  type: TDocumentType;
  publicUrl: string;
  fileName: string;
  params: Record<TDocumentParam, string | number | null>;
  createdAt: string;
  updatedAt: string;
  uploadedAt: string;
};
