import {getBrowserLang, storage} from '@edna/utils';

import {EStorageKeys} from '../constants';

const getCurrentLang = () => {
  const storageLang = storage.get<string>(EStorageKeys.LANG);

  return storageLang ?? getBrowserLang();
};

export {getCurrentLang};
