import {storage} from '@edna/utils';

import {EStorageKeys} from 'src/constants';
import {resetUser, setTenantId} from 'src/containers/User';
import {ECacheTag, listenerMiddleware, rootApi} from 'src/models';

import {
  TCaptcha,
  TChangePasswordFormData,
  TSendOtpPayload,
  TSignInPayload,
  TSignInResponse,
} from './definitions';

const authApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getCaptcha: builder.query<TCaptcha, void>({
      query: () => ({
        method: 'GET',
        url: `/auth/captcha/gen`,
        meta: {isShowError: true},
      }),
      providesTags: [ECacheTag.AUTH_CAPTCHA],
    }),
    signIn: builder.mutation<TSignInResponse, TSignInPayload>({
      query: (data) => ({
        method: 'POST',
        url: `/auth/login`,
        data,
      }),
    }),
    signOut: builder.mutation<TEmptyObject, void>({
      query: () => ({
        method: 'POST',
        url: `/auth/logout`,
        meta: {
          isShowError: true,
        },
      }),
    }),
    changePassword: builder.mutation<void, TChangePasswordFormData>({
      query: (data) => ({
        method: 'POST',
        url: '/auth/password/change',
        data,
        meta: {
          isShowSuccess: true,
          successMessageKey: 'Profile:notification.changePasswordSuccess',
        },
      }),
    }),
    sendOtp: builder.mutation<TSignInResponse, TSendOtpPayload>({
      query: (data) => ({
        method: 'POST',
        url: '/auth/otp',
        data,
      }),
    }),
    resetOtp: builder.mutation<TSignInResponse, Pick<TSendOtpPayload, 'otpId'>>({
      query: (data) => ({
        method: 'POST',
        url: '/auth/otp-reset',
        data,
      }),
    }),
  }),
});

listenerMiddleware.startListening({
  matcher: authApi.endpoints.signIn.matchFulfilled,
  effect: ({payload}, {dispatch}) => {
    storage.set(EStorageKeys.USERNAME, payload.login);

    const tenant =
      payload.tenants.find(({id}) => id === Number(storage.get(EStorageKeys.TENANT_ID))) ??
      payload.tenants[0];

    dispatch(setTenantId(tenant.id));
  },
});

listenerMiddleware.startListening({
  matcher: authApi.endpoints.signOut.matchFulfilled,
  effect: (_, {dispatch}) => {
    dispatch(resetUser());
  },
});

export const {
  useSignInMutation,
  useGetCaptchaQuery,
  useChangePasswordMutation,
  useSignOutMutation,
  useResetOtpMutation,
  useSendOtpMutation,
} = authApi;
