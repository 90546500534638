import {TOperator} from '@edna/utils';

import {EPermission, ERoles} from 'src/containers/User';

enum ECacheTag {
  USER = 'USER',
  FLOW_STEPS = 'FLOW_STEPS',
  FLOW = 'FLOW',
  AUTH_CAPTCHA = 'AUTH_CAPTCHA',
  CHANNELS = 'CHANNELS',
  SYSTEM_CALLBACK = 'SYSTEM_CALLBACK',
  NOTIFICATIONS = 'NOTIFICATIONS',
  NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT',
  CASCADES = 'CASCADES',
  BALANCE = 'BALANCE',
  PARAMETERS = 'PARAMETERS',
  TRANSACTIONS = 'TRANSACTIONS',
  SUPPORT_CLIENTS = 'SUPPORT_CLIENTS',
  LEAD_CLIENTS = 'LEAD_CLIENTS',
  OVERDRAFT = 'OVERDRAFT',
  COMPANY_DOCUMENT = 'COMPANY_DOCUMENT',
  TAGS = 'TAGS',
  SMS_CHANNEL = 'SMS_CHANNEL',
  EMBEDDED_WA_CHANNEL = 'EMBEDDED_WA_CHANNEL',
  PUSH_APPS = 'PUSH_APPS',
  BROADCASTS = 'BROADCASTS',
  AUTOPAYMENT = 'AUTOPAYMENT',
  MESSAGE_MATCHERS = 'MESSAGE_MATCHERS',
  MESSAGE_MATCHERS_LIST = 'MESSAGE_MATCHERS_LIST',
  SUBSCRIBERS = 'SUBSCRIBERS',
  TARIFF_TEMPLATES = 'TARIFF_TEMPLATES',
  TARIFF_CHANGE_REQUESTS = 'TARIFF_CHANGE_REQUESTS',
  TARIFFS = 'TARIFFS',
  PLANNED_TARIFFS = 'PLANNED_TARIFFS',
  LICENSES = 'LICENSES',
  SOLUTION_ID = 'SOLUTION_ID',
}

enum EFixedCacheKey {
  SIGN_IN = 'SIGN_IN',
  CREATE_PARAMETER = 'CREATE_PARAMETER',
  SUBSCRIBER_TAGS = 'SUBSCRIBER_TAGS',
  MESSAGE_MATCHER_UNIQUE_NAME = 'MESSAGE_MATCHER_UNIQUE_NAME',
}

const CACHE_LIFETIME = 5;

type THeaders = Record<string, string>;
type TMeta = Partial<{
  isShowError: boolean;
  errorMessageKey: string;
  i18nOptions: TAnyObject;
  isShowSuccess: boolean;
  successMessageKey: string;
  isBackendError: boolean;
  permissions: TOperator<EPermission>;
  roles: TOperator<ERoles>;
  headers: THeaders;
}> &
  TAnyObject;

const RESERVED_META_KEYS = ['isBackendError', 'headers'];

const REFRESH_API_URL = '/rest/auth/refresh';
const SILENT_POLLING_API_URL = '/rest/web-notification/poll-all';

enum EInternalError {
  ABORT_ERROR = 0,
  NETWORK_ERROR = 'networkError',
}

const unavailableStatusSet = new Set([502, 503, 504]);

export {
  ECacheTag,
  EFixedCacheKey,
  CACHE_LIFETIME,
  RESERVED_META_KEYS,
  REFRESH_API_URL,
  SILENT_POLLING_API_URL,
  EInternalError,
  unavailableStatusSet,
};
export type {THeaders, TMeta};
