import {TBadgeColor} from 'src/components';
import {EChannelType} from 'src/constants';

export enum EStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  PROBLEM = 'PROBLEM',
  NEEDS_ATTENTION = 'NEEDS_ATTENTION',
  DISABLED = 'DISABLED',
  REGISTRATION = 'REGISTRATION',
  CANCELED = 'CANCELED',
}

export enum EWabaStatus {
  ACCOUNT_VIOLATION = 'ACCOUNT_VIOLATION',
  ACCOUNT_RESTRICTION = 'ACCOUNT_RESTRICTION',
  DISABLED = 'DISABLED',
}

export type TStatus = keyof typeof EStatus;

export type TChannel = {
  id: number;
  subjectId: number;
  name: string;
  subjectSubject: string;
  createdAt: string;
  updatedAt: string;
  status: TStatus;
  channelAttribute: string;
  type: EChannelType;
  wabaStatus?: EWabaStatus;
  // WhatsApp
  qualityScore: EQualityScore | null;
  messagingLimit: EMessagingLimit | null;
  previousQualityScore: EQualityScore | null;
  previousMessagingLimit: EMessagingLimit | null;
  qualityUpdatedAt: string;
  qualityStatus: EQualityStatus | null;
  // WhatsApp tech partner
  code?: string;
};

export enum EQualityScore {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  NA = 'NA',
}

export const qualityScorePriorityMap: Record<EQualityScore, number> = {
  [EQualityScore.GREEN]: 3,
  [EQualityScore.YELLOW]: 2,
  [EQualityScore.RED]: 1,
  [EQualityScore.NA]: 0,
};

export enum EQualityStatus {
  CONNECTED = 'CONNECTED',
  FLAGGED = 'FLAGGED',
  RESTRICTED = 'RESTRICTED',
}

export enum EMessagingLimit {
  TIER_50 = 'TIER_50',
  TIER_250 = 'TIER_250',
  TIER_1K = 'TIER_1K',
  TIER_10K = 'TIER_10K',
  TIER_100K = 'TIER_100K',
  TIER_UNLIMITED = 'TIER_UNLIMITED',
  TIER_NOT_SET = 'TIER_NOT_SET',
}

export const limitPriorityMap: Record<EMessagingLimit, number> = {
  [EMessagingLimit.TIER_NOT_SET]: 0,
  [EMessagingLimit.TIER_50]: 1,
  [EMessagingLimit.TIER_250]: 2,
  [EMessagingLimit.TIER_1K]: 3,
  [EMessagingLimit.TIER_10K]: 4,
  [EMessagingLimit.TIER_100K]: 5,
  [EMessagingLimit.TIER_UNLIMITED]: 6,
};

export const qualityStatusColorHash: Record<EQualityStatus, TBadgeColor> = {
  [EQualityStatus.FLAGGED]: 'RED',
  [EQualityStatus.RESTRICTED]: 'PURPLE',
  [EQualityStatus.CONNECTED]: 'GREEN',
};

export type TFilters = {
  filter: string;
  channelTypes: Record<EChannelType, boolean>;
  channelStatuses: Record<EStatus, boolean>;
};
