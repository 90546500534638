import React from 'react';
import {useTranslation} from 'react-i18next';

import {Number} from '@edna/components/primitives';

import Subject from 'src/components/Subject';
import {TSubject} from 'src/constants';

import * as S from './style';

type TNumberedSubjectProps = {
  className?: string;
  subject: TSubject;
  number: number;
  active?: boolean;
};

const NumberedSubject = React.memo<TNumberedSubjectProps>(
  ({className, subject, number, active = false}) => {
    const {t} = useTranslation();
    const name = subject?.name ?? t('NumberedSubject:channel', {count: number});

    return (
      <S.Stack align="center" direction="ROW" size="2" className={className}>
        <Number size="XL" isActive={active}>
          {number}
        </Number>
        {subject && <Subject type={subject.type} name={name} />}
      </S.Stack>
    );
  },
);

NumberedSubject.displayName = 'NumberedSubject';

export default NumberedSubject;
