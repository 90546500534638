import {TAutopaymentError} from 'src/containers/Autopayment';
import {EMessagingLimit, EQualityScore, EQualityStatus} from 'src/containers/Channels/definitions';
import {EWhatsAppCategory} from 'src/containers/MessageMatchers/WhatsAppForm/definitions';
import {EApproveStatus} from 'src/containers/MessageMatchers/definitions';

import {TGenericNotification} from './definitions';

enum EWebNotificationType {
  SubscribersUploadSuccess = 'SubscribersUploadSuccess',
  SubscribersUploadPartial = 'SubscribersUploadPartial',
  SubscribersUploadFailed = 'SubscribersUploadFailed',
  SubscribersUploadLoading = 'SubscribersUploadLoading',

  BroadcastCompleted = 'BroadcastCompleted',
  BroadcastCancelled = 'BroadcastCancelled',

  LicenseExpiring = 'LicenseExpiring',

  CallbackUpdated = 'CallbackUpdated',

  ApikeyUserUpdated = 'ApikeyUserUpdated',

  CompanyDetailsUpdated = 'CompanyDetailsUpdated',
  PaymentDetailsUpdated = 'PaymentDetailsUpdated',
  TenantTariffAccepted = 'TenantTariffAccepted',
  AgreedChangeTariffRequest = 'AgreedChangeTariffRequest',
  NotAgreedChangeTariffRequest = 'NotAgreedChangeTariffRequest',
  JivoEnabled = 'JivoEnabled',
  JivoDisabled = 'JivoDisabled',

  MessageMatcherStatusChanged = 'MessageMatcherStatusChanged',
  MessageMatcherCategoryChanged = 'MessageMatcherCategoryChanged',

  InvoicePaid = 'InvoicePaid',
  StripeCheckout = 'StripeCheckout',

  ChannelProfilePartnerProfileCreated = 'ChannelProfilePartnerProfileCreated',
  ChannelProfileQualityScoreChanged = 'ChannelProfileQualityScoreChanged',
  ChannelProfileMessagingLimitChanged = 'ChannelProfileMessagingLimitChanged',
  ChannelProfileQualityStatusChanged = 'ChannelProfileQualityStatusChanged',
  ChannelProfileWABAViolation = 'ChannelProfileWABAViolation',
  ChannelProfileWABARestriction = 'ChannelProfileWABARestriction',
  ChannelProfileWABADisable = 'ChannelProfileWABADisable',
  ChannelProfileWABAReinstate = 'ChannelProfileWABAReinstate',
}

type TChannelProfilePartnerProfileCreated = TGenericNotification<
  EWebNotificationType.ChannelProfilePartnerProfileCreated,
  {
    name: string;
  }
>;

type TChannelProfileQualityStatusChangedNotification = TGenericNotification<
  EWebNotificationType.ChannelProfileQualityStatusChanged,
  {
    whatsAppProfileQualityStatusChanged: {
      name: string;
      qualityStatus: EQualityStatus;
    };
  }
>;

type TChannelProfileMessagingLimitChangedNotification = TGenericNotification<
  EWebNotificationType.ChannelProfileMessagingLimitChanged,
  {
    whatsAppProfileMessagingLimitChanged: {
      name: string;
      messagingLimit: EMessagingLimit;
    };
  }
>;

type TChannelProfileQualityScoreChangedNotification = TGenericNotification<
  EWebNotificationType.ChannelProfileQualityScoreChanged,
  {
    whatsAppProfileQualityScoreChanged: {
      name: string;
      qualityScore: EQualityScore;
    };
  }
>;

type TChannelProfileViolationNotification = TGenericNotification<
  EWebNotificationType.ChannelProfileWABAViolation,
  {
    whatsAppESProfileWABAViolation: {
      wabaId: number;
      violationType: string;
      whatsappEsProfileInternalNames: string[];
    };
  }
>;

type TChannelProfileRestrictionNotification = TGenericNotification<
  EWebNotificationType.ChannelProfileWABARestriction,
  {
    whatsAppESProfileWABARestriction: {
      wabaId: number;
      expirationDate: string;
      whatsappEsProfileInternalNames: string[];
    };
  }
>;

type TChannelProfileDisableNotification = TGenericNotification<
  EWebNotificationType.ChannelProfileWABADisable,
  {
    whatsAppESProfileWABADisable: {
      wabaId: number;
      whatsappEsProfileInternalNames: string[];
    };
  }
>;

type TChannelProfileReinstateNotification = TGenericNotification<
  EWebNotificationType.ChannelProfileWABAReinstate,
  {
    whatsAppESProfileWABAReinstate: {
      wabaId: number;
      whatsappEsProfileInternalNames: string[];
    };
  }
>;

type TMessageMatcherCategoryChangedNotification = TGenericNotification<
  EWebNotificationType.MessageMatcherCategoryChanged,
  {
    messageMatcherCategoryChanged: {
      name: string;
      newCategory: EWhatsAppCategory;
    };
  }
>;

type TMessageMatcherStatusChangedNotification = TGenericNotification<
  EWebNotificationType.MessageMatcherStatusChanged,
  {
    messageMatcher: {
      name: string;
      approveStatus: EApproveStatus;
    };
  }
>;

type TJivoNotification = TGenericNotification<
  EWebNotificationType.JivoDisabled | EWebNotificationType.JivoEnabled,
  {
    updateJivoIntegration: {
      login: string;
    };
  }
>;

type TAgreedChangeTariffRequestNotification = TGenericNotification<
  EWebNotificationType.AgreedChangeTariffRequest,
  {
    agreedChangeTariffRequest: {
      login: string;
      tariffName: string;
    };
  }
>;

type TNotAgreedChangeTariffRequestNotification = TGenericNotification<
  EWebNotificationType.NotAgreedChangeTariffRequest,
  {
    notAgreedChangeTariffRequest: {
      login: string;
      tariffName: string;
    };
  }
>;

type TTenantTariffAcceptedNotification = TGenericNotification<
  EWebNotificationType.TenantTariffAccepted,
  {
    acceptTenantTariff: {
      login: string;
    };
  }
>;

type TPaymentDetailsUpdatedNotification = TGenericNotification<
  EWebNotificationType.PaymentDetailsUpdated,
  {
    updatePaymentDetails: {
      login: string;
    };
  }
>;

type TCompanyDetailsUpdatedNotification = TGenericNotification<
  EWebNotificationType.CompanyDetailsUpdated,
  {
    updateCompanyDetails: {
      login: string;
    };
  }
>;

type TLicenseExpiringNotification = TGenericNotification<
  EWebNotificationType.LicenseExpiring,
  {
    licenseExpiring: {
      expireAt: string;
    };
  }
>;

type TSubscribersUploadNotification = TGenericNotification<
  | EWebNotificationType.SubscribersUploadFailed
  | EWebNotificationType.SubscribersUploadLoading
  | EWebNotificationType.SubscribersUploadPartial
  | EWebNotificationType.SubscribersUploadSuccess,
  {
    subscriberUpload: {
      uploadId: number;
      fileName?: string;
      reportUrl?: string;
      errorReportFileName?: string;
      uploadedInTotal?: number;
      uploadedWithErrors?: number;
    };
  }
>;

type TBroadcastNotification = TGenericNotification<
  EWebNotificationType.BroadcastCancelled | EWebNotificationType.BroadcastCompleted,
  {
    broadcast: {
      id: number;
      name: string;
    };
  }
>;

enum EAutopaymentNotificationStatus {
  AUTO_REPLENISHMENT_ENABLED = 'AUTO_REPLENISHMENT_ENABLED',
  AUTO_REPLENISHMENT_DISABLED = 'AUTO_REPLENISHMENT_DISABLED',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
}

type TStripeAutopaymentNotification = TGenericNotification<
  EWebNotificationType.StripeCheckout,
  {
    checkoutData: TAutopaymentError & {
      status: EAutopaymentNotificationStatus;
      hostedReceiptUrl?: string;
    };
  }
>;

type TWebNotification =
  | TChannelProfilePartnerProfileCreated
  | TChannelProfileQualityStatusChangedNotification
  | TChannelProfileMessagingLimitChangedNotification
  | TChannelProfileQualityScoreChangedNotification
  | TChannelProfileViolationNotification
  | TChannelProfileRestrictionNotification
  | TChannelProfileDisableNotification
  | TChannelProfileReinstateNotification
  | TMessageMatcherCategoryChangedNotification
  | TMessageMatcherStatusChangedNotification
  | TJivoNotification
  | TAgreedChangeTariffRequestNotification
  | TPaymentDetailsUpdatedNotification
  | TNotAgreedChangeTariffRequestNotification
  | TTenantTariffAcceptedNotification
  | TCompanyDetailsUpdatedNotification
  | TLicenseExpiringNotification
  | TSubscribersUploadNotification
  | TBroadcastNotification
  | TStripeAutopaymentNotification
  | TGenericNotification<
      | EWebNotificationType.CallbackUpdated
      | EWebNotificationType.ApikeyUserUpdated
      | EWebNotificationType.InvoicePaid
    >;

export type {
  TStripeAutopaymentNotification,
  TBroadcastNotification,
  TSubscribersUploadNotification,
  TLicenseExpiringNotification,
  TCompanyDetailsUpdatedNotification,
  TPaymentDetailsUpdatedNotification,
  TTenantTariffAcceptedNotification,
  TNotAgreedChangeTariffRequestNotification,
  TAgreedChangeTariffRequestNotification,
  TJivoNotification,
  TMessageMatcherStatusChangedNotification,
  TMessageMatcherCategoryChangedNotification,
  TChannelProfileQualityScoreChangedNotification,
  TChannelProfileMessagingLimitChangedNotification,
  TChannelProfilePartnerProfileCreated,
  TChannelProfileQualityStatusChangedNotification,
  TChannelProfileViolationNotification,
  TChannelProfileRestrictionNotification,
  TChannelProfileDisableNotification,
  TChannelProfileReinstateNotification,
  TWebNotification,
};
export {EWebNotificationType, EAutopaymentNotificationStatus};
