import styled, {css} from 'styled-components';

import {mixins} from '@edna/components/styles';
import SmallArrowIcon from '@edna/icons/arrowUpward.svg';
import BaseCloseIcon from '@edna/icons/close.svg';
import {hexToRgba} from '@edna/utils';

import {ANIMATION_DURATION, EPosition} from './definitions';

const OVERLAY_ZINDEX = 999_996;
const HIGHLIGHTER_ZINDEX = 999_997;
const HIGHLIGHT_ZINDEX = 999_998;
const HINT_ZINDEX = 999_999;

const HINT_WIDTH = 390;

const EASING = 'ease-out';

const Overlay = styled.div`
  inset: 0;
  position: fixed;
  z-index: ${OVERLAY_ZINDEX};
  opacity: 0;
  cursor: pointer;
`;

const Highlighter = styled.div`
  position: absolute;
  z-index: ${HIGHLIGHTER_ZINDEX};
  transition: all ${ANIMATION_DURATION}ms ${EASING};

  opacity: 1;

  ${({theme}) => css`
    border-radius: ${theme.borderRadius.br2};
    box-shadow: ${hexToRgba(theme.branding.content.primary, '50%')} 0 0 0 5000px;
  `}
`;

const Hint = styled.div<{position: EPosition}>`
  position: absolute;
  visibility: visible;
  width: ${HINT_WIDTH}px;
  transition: all ${ANIMATION_DURATION}ms ${EASING};

  ${({theme}) => css`
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.s4};
    border-radius: ${theme.borderRadius.br2};
    box-shadow: ${theme.boxShadow.dropdown};
  `}

  ${({position, theme}) => {
    switch (position) {
      case EPosition.LEFT:
        return css`
          top: 50%;
          left: 0;
          transform: translate(calc(-100% - ${theme.spacing.s2}), -50%);
        `;
      case EPosition.RIGHT:
        return css`
          top: 50%;
          left: 100%;
          transform: translate(${theme.spacing.s2}, -50%);
        `;
      case EPosition.TOP:
        return css`
          top: 0;
          left: 50%;
          transform: translate(-50%, calc(-100% - ${theme.spacing.s2}));
        `;
      case EPosition.BOTTOM:
        return css`
          top: 100%;
          left: 50%;
          transform: translate(-50%, ${theme.spacing.s2});
        `;
    }
  }}
`;

const HintReference = styled.div`
  position: absolute;
  visibility: hidden;
  z-index: ${HINT_ZINDEX};
  background-color: transparent;
  transition: all ${ANIMATION_DURATION}ms ${EASING};
`;

const StepperDot = styled.div<{isActive?: boolean}>`
  ${mixins.wh('8px')}
  border-radius: 50%;

  ${({theme, isActive}) => css`
    background: ${theme.branding.content.tertiary};

    ${isActive &&
    css`
      background: ${theme.branding.content.primary};
    `}
  `}
`;

const CloseIcon = styled(BaseCloseIcon)`
  cursor: pointer;
  ${mixins.wh('16px')}
  ${({theme}) => css`
    color: ${theme.branding.content.tertiary};
  `}
`;

const LeftArrowIcon = styled(SmallArrowIcon)`
  ${mixins.wh('16px')}
  transform: rotate(270deg);
`;
const RightArrowIcon = styled(LeftArrowIcon)`
  transform: rotate(90deg);
`;

export {
  Overlay,
  Highlighter,
  Hint,
  HintReference,
  CloseIcon,
  StepperDot,
  LeftArrowIcon,
  RightArrowIcon,
  HIGHLIGHT_ZINDEX,
};
