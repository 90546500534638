import React, {useContext, useEffect} from 'react';
import {UNSAFE_NavigationContext} from 'react-router-dom';

import type {Blocker, History} from 'history';

const useRouterBlocker = (blocker: Blocker, isActive: boolean) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {navigator} = useContext(UNSAFE_NavigationContext) as any as {navigator: History};

  const unblockRef = React.useRef(() => {});

  useEffect(() => {
    if (!isActive) {
      return;
    }

    unblockRef.current = navigator.block((transition) => {
      blocker({
        ...transition,
        retry() {
          unblockRef.current();
          transition.retry();
        },
      });
    });

    return unblockRef.current;
  }, [navigator, blocker, isActive]);

  const unblock = React.useCallback(() => {
    unblockRef.current();
  }, []);

  return unblock;
};

export {useRouterBlocker};
