import {TCurrency} from '@edna/utils';

import {EChannelType} from 'src/constants';

export enum ELicenseStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  ACTIVE_DEBT = 'ACTIVE_DEBT',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  TEMPORARY_BLOCKED = 'TEMPORARY_BLOCKED',
}

export type TLicenseStatus = keyof typeof ELicenseStatus;

export type TCommonLicense = {
  id: number;
  channelType: EChannelType;
  tenantId: number;
  name: string;
  description: string;
  price: number;
  currency: TCurrency;
  status: TLicenseStatus;
  createdAt: string;
  updatedAt: string;
  activatedAt: string;
  expiredAt: string;
  cancelledAt: string;
  activeUntil: string;
  paidUntil: string;
  temporaryblockedAt: string;
};

export type TWhatsAppLicense = TCommonLicense & {
  channelType: EChannelType.WHATSAPP;
  subjectId: number;
};

export type TSmsLicense = TCommonLicense & {
  channelType: EChannelType.SMS;
  periodMonths: number;
};

export type TViberLicense = TCommonLicense & {
  channelType: EChannelType.VIBER;
  periodMonths: number;
};

export type TLicense = TWhatsAppLicense | TSmsLicense | TViberLicense;
