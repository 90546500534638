import {
  Field,
  FieldError,
  FieldErrors,
  FieldValues,
  ResolverOptions,
  ResolverResult,
  UnpackNestedValue,
  get,
} from 'react-hook-form';

import i18next from 'i18next';
import Joi, {
  ArraySchema,
  AsyncValidationOptions,
  ValidationError,
  ValidationErrorItem,
  ValidationOptions,
} from 'joi';
import has from 'lodash/has';
import set from 'lodash/set';

import {formatDate} from '@edna/utils';

import {TRule as TPasswordRule, isValidPassword} from '../password';
import {EJoiError} from './definitions';
import {isInvalidTimeFormat} from './isInvalidTimeFormat';
import {
  isValidASCII,
  isValidBIC,
  isValidHttpsUrl,
  isValidKPP,
  isValidPhone,
  isValidTIN,
  isValidUrl,
} from './isValid';
import {isValidBankAccount} from './isValidBankAccount';

const MAX_VIBRATE = 30000;

const joi = Joi.defaults((schema) => {
  switch (schema.type) {
    case 'number':
    case 'string':
      return schema.empty(['', null]);
    case 'array':
      return (schema as ArraySchema).sparse(true).empty(null);
    default:
      return schema.empty(null);
  }
});

const mixed = {
  id: joi.alternatives().try(joi.string(), joi.number()),
  email: joi.string().email({tlds: {allow: false}}),
  passwordConfirm: (ref: string) =>
    joi
      .string()
      .required()
      .custom((value, helpers) => {
        const [values] = helpers.state.ancestors;

        if (value && values[ref] !== value) {
          return helpers.error('passwordsDoNotMatch');
        }

        return value;
      }),
  url: joi.string().custom((value, helpers) => {
    if (value && !isValidUrl(value)) {
      return helpers.error('invalidUrl');
    }

    return value;
  }),
  httpsUrl: joi.string().custom((value, helpers) => {
    if (value && !isValidHttpsUrl(value)) {
      return helpers.error('invalidUrl');
    }

    return value;
  }),
  noEmoji: joi.string().custom((value, helpers) => {
    if (value && !/^\P{Extended_Pictographic}*$/u.test(value)) {
      return helpers.error('invalidNoEmoji');
    }

    return value;
  }),
  timeFormat: joi.string().custom((value, helpers) => {
    if (value && isInvalidTimeFormat(value)) {
      return helpers.error('invalidTimeFormat');
    }

    return value;
  }),
  kpp: joi.string().custom((value, helpers) => {
    if (value && !isValidKPP(value)) {
      return helpers.error('invalidKPP');
    }

    return value;
  }),
  tin: joi.string().custom((value, helpers) => {
    if (value && !isValidTIN(value)) {
      return helpers.error('invalidTIN');
    }

    return value;
  }),
  unp: joi
    .string()
    .length(9)
    .custom((value, helpers) => {
      if (value && !value.match(/^[A-Za-z0-9]*$/)) {
        return helpers.error('invalidTIN');
      }

      return value;
    }),
  bic: joi.string().custom((value, helpers) => {
    if (value && !isValidBIC(value)) {
      return helpers.error('invalidBIC');
    }

    return value;
  }),
  operBankAccount: joi
    .string()
    .max(512)
    .custom((value, helpers) => {
      const [values] = helpers.state.ancestors;

      if (!!values?.bic && !isValidBankAccount(values.operBankAccount, values.bic)) {
        return helpers.error('invalidOperBankAccount');
      }

      return value;
    }),
  corrBankAccount: joi
    .string()
    .max(512)
    .custom((value, helpers) => {
      const [values] = helpers.state.ancestors;

      if (!!values?.bic && !isValidBankAccount(values.corrBankAccount, values.bic, true)) {
        return helpers.error('invalidCorrBankAccount');
      }

      return value;
    }),
  password: (passwordRules: TPasswordRule[], checkOldPassword?: boolean) =>
    joi.string().custom((value, helpers) => {
      if (value && !isValidPassword(value, passwordRules)) {
        return helpers.error('invalidPassword');
      }
      const [values] = helpers.state.ancestors;

      if (checkOldPassword && value && values.oldPassword === value) {
        return helpers.error('passwordsIsEqual');
      }

      return value;
    }),
  phone: joi.string().custom((value, helpers) => {
    if (value && !isValidPhone(value)) {
      return helpers.error('invalidPhone');
    }

    return value;
  }),
  file: joi.custom((value, helpers) => {
    if (value && !(value instanceof File)) {
      return helpers.error('invalidFile');
    }

    return value;
  }),
  fileDTO: (isRequired?: boolean) =>
    joi
      .object({
        fileUrl: joi.string(),
      })
      .options({presence: isRequired ? 'required' : 'optional'}),
  textWithVariables: joi.string().custom((value, helpers) => {
    if (value.match(/{{}}/)) {
      return helpers.error('emptyVariable');
    }

    return value;
  }),
  hexColor: joi.string().custom((value, helpers) => {
    if (value && !/^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/i.test(value)) {
      return helpers.error('string.hex');
    }

    return value;
  }),
  ascii: joi.string().custom((value, helpers) => {
    if (value && !isValidASCII(value)) {
      return helpers.error('passwordHasNotASCII');
    }

    return value;
  }),
  vibrate: joi.string().custom((value, helpers) => {
    const isInvalid = value
      .replace(/[\[\]]/g, '')
      .split(',')
      .some((item: string) => Number(item) > MAX_VIBRATE);

    if (isInvalid) {
      return helpers.error('invalidVibrate', {
        limit: MAX_VIBRATE,
      });
    }

    return value;
  }),
  variables: joi.string().custom((value, helpers) => {
    if (/{{}}/g.test(value)) {
      return helpers.error(EJoiError.EMPTY_VARIABLES);
    }

    return value;
  }),
};

const oldHandleErrors = (errors: TAnyObject, error: ValidationErrorItem) => {
  const path = error.path.join('.');

  if (has(errors, path)) {
    return errors;
  }
  const errorContext = error.context ?? {};
  let errorArgs: TAnyObject = {};

  switch (error.type) {
    case 'alternatives.match': {
      ((error.context?.details ?? []) as ValidationErrorItem[]).forEach((error1) =>
        oldHandleErrors(errors, error1),
      );

      return errors;
    }
    case 'array.min':
    case 'array.max':
    case 'string.min':
    case 'string.max':
    case 'string.length':
      errorArgs = {
        count: errorContext.limit,
      };
      break;
    case 'number.min':
    case 'number.max':
      errorArgs = {
        limit: errorContext.limit,
      };
      break;
    case 'date.min':
    case 'date.max':
      errorArgs = {
        limit: formatDate(errorContext.limit, {format: 'dd.MM.yyyy'}),
      };
      break;
    default:
      errorArgs = errorContext;
      break;
  }
  set(
    errors,
    path,
    i18next.t(
      error.type.startsWith('^') ? error.type.replace('^', '') : `validator:${error.type}`,
      errorArgs,
    ),
  );

  return errors;
};

const newHandleErrors = (error: ValidationErrorItem) => {
  const errorContext = error.context ?? {};
  let options: TAnyObject;

  switch (error.type) {
    case 'array.min':
    case 'array.max':
    case 'string.min':
    case 'string.max':
    case 'string.length':
      options = {
        count: errorContext.limit,
      };
      break;
    case 'number.min':
    case 'number.max':
      options = {
        limit: errorContext.limit,
      };
      break;
    case 'date.min':

    case 'date.max': {
      const date = new Date(errorContext.limit);
      const hasMinutes = date.getHours() > 0 || date.getMinutes() > 0;

      options = {
        limit: formatDate(date, {
          format: `dd.MM.yyyy${hasMinutes ? 'HH:mm' : ''}`,
        }),
      };
      break;
    }
    default:
      options = errorContext;
      break;
  }

  return {
    key: error.type.startsWith('^') ? error.type.replace('^', '') : `validator:${error.type}`,
    options,
  };
};

const validator = (creator: TCreateValidateSchema, options?: ValidationOptions) => {
  const schema = creator(joi, mixed).options({
    abortEarly: false,
    allowUnknown: true,
    ...options,
  });

  return (values: TAnyObject) =>
    schema.validate(values).error?.details.reduce(oldHandleErrors, {}) ?? {};
};

const GLOBAL_ERROR_KEY = 'GLOBAL_ERROR';

const getGlobalError = (errors: FieldErrors<TAnyObject>, key = GLOBAL_ERROR_KEY) =>
  errors[key] as FieldError;

// TODO перенести в edna либу
const parseErrorSchema = <TFieldValues extends TAnyObject = TAnyObject>(
  {details}: ValidationError,
  resolverOptions: ResolverOptions<TFieldValues>,
  handleErrors: (error: ValidationErrorItem) => {
    key: string;
    options: TAnyObject;
  },
) =>
  (details.reduce((errors, error) => {
    const path = error.path.join('.') || GLOBAL_ERROR_KEY;
    const field = get(resolverOptions.fields, path) as Field['_f'] | undefined;

    if (has(errors, path)) {
      return errors;
    }
    const {key, options} = handleErrors(error);

    set(errors, path, {
      message: key,
      type: error.type,
      ref: {...field?.ref, name: path, value: options},
    });

    return errors;
  }, {}) ?? {}) as FieldErrors<TFieldValues>;

// TODO перенести в edna либу
type Resolver = <TFieldValues extends FieldValues, TContext extends AsyncValidationOptions>(
  values: UnpackNestedValue<TFieldValues>,
  context: TContext | undefined,
  options: ResolverOptions<TFieldValues>,
) => Promise<ResolverResult<TFieldValues>>;

const joiResolver = (creator: TCreateValidateSchema, options?: ValidationOptions): Resolver => {
  const schema = creator(joi, mixed).options({
    abortEarly: false,
    allowUnknown: true,
    ...options,
  });

  return async (values, context, resolverOptions) => {
    try {
      return {
        values: await schema.validateAsync(values, context),
        errors: {},
      };
    } catch (error) {
      return {
        values: {},
        errors: parseErrorSchema(error as ValidationError, resolverOptions, newHandleErrors),
      };
    }
  };
};

export {getGlobalError, validator, joi, mixed, joiResolver, isValidUrl};
