import {ESupportProfileTabs} from '../definitions';
import {EConfigsTab} from './ConfigsTab/definitions';

export default {
  availableTariffs: 'Available pricing',
  title: 'Client card',
  licenseStatus: 'Current license status',
  expiredAt: 'until ',
  responsibleManager: 'Responsible manager edna',
  partner: 'Agent',
  taxNumber: 'Taxpayer Identification Number (TIN)',
  country: 'Country',
  currency: 'Currency',
  reseller: 'Jurisdiction',
  contact: 'Contact details',
  contactName: 'Contact details',
  phone: 'Phone number',
  companyName: 'Company name',
  companyData: 'Company details',
  empty: 'Not specified',
  tariffs: 'Pricing',
  responsibleManagerModal: {
    title: 'Change responsible manager',
    selectManager: 'Select a responsible manager',
  },
  tabs: {
    [ESupportProfileTabs.PAYMENT]: 'Payment',
    [ESupportProfileTabs.REQUISITES]: 'Company details',
    [ESupportProfileTabs.PRICING]: 'Pricing',
    [ESupportProfileTabs.CONFIGS]: 'Configurations',
    [ESupportProfileTabs.LICENSE]: 'License',
  },
  configsTabs: {
    [EConfigsTab.CALLBACK]: 'API & Callback',
    [EConfigsTab.SETTINGS]: 'Other settings',
  },
};
