import React from 'react';

import without from 'lodash/without';

import {ECurrency} from '@edna/utils';

import {
  BeelineIcon,
  FlagRuIcon,
  InstagramCircleIcon,
  InstagramIcon,
  KTelecomIcon,
  KtkTelecomIcon,
  MegafonIcon,
  MotivIcon,
  MtsIcon,
  NotifyIcon,
  OkNotifyIcon,
  PushCircleIcon,
  PushIcon,
  SberIcon,
  SmsCircleIcon,
  SmsIcon,
  Tele2Icon,
  ViberCircleIcon,
  ViberIcon,
  VkNotifyIcon,
  WhatsAppCircleIcon,
  WhatsAppIcon,
  WorldIcon,
} from 'src/icons';

enum ELanguage {
  ru = 'ru',
  en = 'en',
}

type TLanguage = keyof typeof ELanguage;

enum EStorageKeys {
  NEWS = 'NEWS',
  TENANT_ID = 'TENANT_ID',
  LANG = 'LANG',
  NOTIFICATION_TIME = 'NOTIFICATION_TIME',
  USERNAME = 'USERNAME',
  MENU_OPEN = 'MENU_OPEN',
  BROADCASTS_FILTERS = 'BROADCASTS_FILTERS',
  CASCADES_FILTERS = 'CASCADES_FILTERS',
  CHANNELS_FILTERS = 'CHANNELS_FILTERS',
  LEAD_COMPANY_CLIENTS_FILTERS = 'LEAD_COMPANY_CLIENTS_FILTERS',
  MESSAGE_MATCHERS_FILTERS = 'MESSAGE_MATCHERS_FILTERS',
  SUPPORT_CLIENTS_FILTERS = 'SUPPORT_CLIENTS_FILTERS',
  SUBSCRIBERS_FILTERS = 'SUBSCRIBERS_FILTERS',
}

enum ESortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

enum EOperatorName {
  RuMts = 'russia-mts',
  RuBeeline = 'russia-beeline',
  RuTele2 = 'russia-tele2',
  RuMegafon = 'russia-megafon',
  RuYota = 'russia-yota',
  RuVainahtelecom = 'russia-vainah_telecom',
  RuMotiv = 'russia-motiv',
  RuKtelecom = 'russia-ktelecom',
  RuKtktelecom = 'russia-ktktelecom',
  RuSbertelecom = 'russia-sbertelecom',
  RuDefault = 'russia-default', // Прочие операторы России
  IoDefault = 'io-default', // Международные операторы
}

export const EmptyIcon = () => null;

export const OPERATOR_ICONS: Record<EOperatorName, TSvgComponentType> = {
  [EOperatorName.RuMts]: MtsIcon,
  [EOperatorName.RuBeeline]: BeelineIcon,
  [EOperatorName.RuTele2]: Tele2Icon,
  [EOperatorName.RuMegafon]: MegafonIcon,
  [EOperatorName.RuYota]: EmptyIcon,
  [EOperatorName.RuVainahtelecom]: EmptyIcon,
  [EOperatorName.RuMotiv]: MotivIcon,
  [EOperatorName.RuKtelecom]: KTelecomIcon,
  [EOperatorName.RuKtktelecom]: KtkTelecomIcon,
  [EOperatorName.RuSbertelecom]: SberIcon,
  [EOperatorName.RuDefault]: FlagRuIcon,
  [EOperatorName.IoDefault]: WorldIcon,
};

enum EBlrOperatorName {
  BlrA1 = 'belarus-a1',
  BlrMts = 'belarus-mts',
  BlrLife = 'belarus-life',
  BlrDefault = 'belarus-default',
}

enum EChannelType {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  VIBER = 'VIBER',
  INSTAGRAM = 'INSTAGRAM',
  PUSH = 'PUSH',
  EMBEDDED_WHATSAPP = 'EMBEDDED_WHATSAPP',
  VK_NOTIFY = 'VK_NOTIFY',
  OK_NOTIFY = 'OK_NOTIFY',
  // IMESSAGE = 'IMESSAGE',
  // TELEGRAM = 'TELEGRAM',
  // VIBERPA = 'VIBERPA',
  // FB = 'FB',
  // VK = 'VK',
  // YANDEX = 'YANDEX',
  // MIR = 'MIR',
}

const channelTypeHash: Record<EChannelType, string> = {
  [EChannelType.SMS]: 'sms',
  [EChannelType.WHATSAPP]: 'whatsapp',
  [EChannelType.VIBER]: 'viber',
  [EChannelType.INSTAGRAM]: 'instagram',
  [EChannelType.PUSH]: 'push',
  [EChannelType.EMBEDDED_WHATSAPP]: 'embedded-signup',
  [EChannelType.VK_NOTIFY]: 'vk-notify',
  [EChannelType.OK_NOTIFY]: 'ok-notify',
};

const CHANNEL_TYPES = without(
  Object.values(EChannelType),
  EChannelType.EMBEDDED_WHATSAPP,
  EChannelType.INSTAGRAM,
);

type TChannelType = keyof typeof EChannelType;

const CHANNEL_TYPE: Record<TChannelType, string> = {
  [EChannelType.SMS]: 'SMS',
  [EChannelType.VIBER]: 'Viber',
  [EChannelType.WHATSAPP]: 'WhatsApp',
  [EChannelType.EMBEDDED_WHATSAPP]: 'WhatsApp',
  [EChannelType.INSTAGRAM]: 'Instagram',
  [EChannelType.PUSH]: 'Push',
  [EChannelType.VK_NOTIFY]: 'VK Notify',
  [EChannelType.OK_NOTIFY]: 'OK Notify',
};

enum EContentType {
  TEXT = 'TEXT',
  VCARD = 'VCARD',
  IMAGE = 'IMAGE',
  BUTTON = 'BUTTON',
  TYPING = 'TYPING',
  DOCUMENT = 'DOCUMENT',
  LOCATION = 'LOCATION',
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  TYPING_END = 'TYPING_END',
  LIST_PICKER = 'LIST_PICKER',
  TIMEPICKER = 'TIMEPICKER',
  OAUTH = 'OAUTH',
  APPLE_PAY = 'APPLE_PAY',
  EXTENSION = 'EXTENSION',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  SUBSCRIBE = 'SUBSCRIBE',
  CONVERSATION_STARTER = 'CONVERSATION_STARTER',
  AUTHENTICATION = 'AUTHENTICATION',
  CONTACT = 'CONTACT',
}

type TContentType = keyof typeof EContentType;

const channelIconHash = {
  [EChannelType.SMS]: SmsCircleIcon,
  [EChannelType.VIBER]: ViberCircleIcon,
  [EChannelType.WHATSAPP]: WhatsAppCircleIcon,
  [EChannelType.EMBEDDED_WHATSAPP]: WhatsAppCircleIcon,
  [EChannelType.INSTAGRAM]: InstagramCircleIcon,
  [EChannelType.PUSH]: PushCircleIcon,
  [EChannelType.VK_NOTIFY]: VkNotifyIcon,
  [EChannelType.OK_NOTIFY]: OkNotifyIcon,
};

const channelBaseIconHash = {
  [EChannelType.SMS]: SmsIcon,
  [EChannelType.VIBER]: ViberIcon,
  [EChannelType.WHATSAPP]: WhatsAppIcon,
  [EChannelType.EMBEDDED_WHATSAPP]: WhatsAppIcon,
  [EChannelType.INSTAGRAM]: InstagramIcon,
  [EChannelType.PUSH]: PushIcon,
  [EChannelType.VK_NOTIFY]: NotifyIcon,
  [EChannelType.OK_NOTIFY]: NotifyIcon,
};

enum EAudienceGroupState {
  included = 'included',
  excluded = 'excluded',
}

type TAudienceGroupState = keyof typeof EAudienceGroupState;

enum EEvent {
  EDITOR_WITH_SELECTOR = 'EDITOR_WITH_SELECTOR',
  MENU_INTERACTION = 'MENU_INTERACTION',
}

enum ETimeUnit {
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds',
}

type TTimeUnit = keyof typeof ETimeUnit;

const TIME_UNITS = Object.values(ETimeUnit);

enum ESubjectStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  NOT_SENT = 'NOT_SENT',
}

type TSubject = {
  id: number;
  name: string;
  subject: string;
  type: TChannelType;
  locked: boolean;
  status: keyof typeof ESubjectStatus;
  testing: boolean;
  updatedAt: string;
  timeZone?: string;
  statusComment?: string;
  createdAt?: string;
  description?: string;
};

enum ETenantCode {
  TENANT_TEMPORARY_BLOCKED = 'tenant.temporary-blocked',
}

const EN_DOMAIN_POSTFIX = '.io';
const RU_DOMAIN_POSTFIX = '.ru';
const PROD_DOMAINS = ['app.edna.ru', 'app.edna.io'];

const isEnDomain = () => window.location.hostname.endsWith(EN_DOMAIN_POSTFIX);
const isProdDomain = () => PROD_DOMAINS.includes(window.location.hostname);
const isEnvDevelopment = process.env.NODE_ENV === 'development';

const getDomainByLanguage = (isRu: boolean) => {
  const domain = window.location.hostname;

  if (isRu) {
    return domain.replace(new RegExp(`${EN_DOMAIN_POSTFIX}$`), RU_DOMAIN_POSTFIX);
  }

  return domain.replace(new RegExp(`${RU_DOMAIN_POSTFIX}$`), EN_DOMAIN_POSTFIX);
};

enum EBilledEventState {
  STORNED = 'STORNED',
  STORNO = 'STORNO',
  CLOSE_DEBT = 'CLOSE_DEBT',
}

type TBilledEventState = keyof typeof EBilledEventState;

enum ESubmitType {
  SAVE = 'save',
  REGISTER = 'register',
}

type TItemId = string | number;

type TTenantDependentPayload<T = TAnyObject> = {
  supportTenantId?: TItemId;
  data: T;
};

type TFiltersProps<T = TAnyObject> = {
  filters: T;
  hasFilters: boolean;
  resetFilters: TEmptyFunction;
  toggleFilter: TEmptyFunction;
  setFilters: React.Dispatch<React.SetStateAction<T>>;
};

const DEFAULT_DEBOUNCE_DELAY = 500;

const MAX_CALENDAR_DATE = new Date(2050, 0, 1);
const XLSX_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export {
  ESortDirection,
  ECurrency,
  ELanguage,
  ESubmitType,
  EBilledEventState,
  ETenantCode,
  ESubjectStatus,
  EEvent,
  TIME_UNITS,
  EContentType,
  isEnDomain,
  isProdDomain,
  getDomainByLanguage,
  isEnvDevelopment,
  RU_DOMAIN_POSTFIX,
  EN_DOMAIN_POSTFIX,
  CHANNEL_TYPE,
  CHANNEL_TYPES,
  EAudienceGroupState,
  EStorageKeys,
  EOperatorName,
  EBlrOperatorName,
  channelBaseIconHash,
  channelIconHash,
  channelTypeHash,
  EChannelType,
  DEFAULT_DEBOUNCE_DELAY,
  MAX_CALENDAR_DATE,
  XLSX_MIME_TYPE,
};

export type {
  TFiltersProps,
  TTenantDependentPayload,
  TItemId,
  TContentType,
  TBilledEventState,
  TSubject,
  TLanguage,
  TTimeUnit,
  TAudienceGroupState,
  TChannelType,
};
