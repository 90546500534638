import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {skipToken} from '@reduxjs/toolkit/query';

import {
  Modal,
  ModalActions,
  ModalCancelButton,
  ModalContent,
  ModalHeader,
  ModalSubmitButton,
  SectionWithBorderField,
  showModal,
} from '@edna/components';
import {FieldError} from '@edna/components/FormField';
import {Stack} from '@edna/components/primitives';
import {useOnChange} from '@edna/hooks';
import {ECurrency, TCurrency} from '@edna/utils';

import {Message} from 'src/components';
import {Hint, SecondaryText, Text} from 'src/components/primitives';
import {useGetSupportClientQuery} from 'src/containers/SupportClients/api';
import {joiResolver} from 'src/utils/validator';

import {useGetTenantDataQuery} from '../User';
import {
  useConnectAutopaymentMutation,
  useGetAutopaymentInfoQuery,
  useGetAutopaymentRestrictionsQuery,
  useUpdateAutopaymentSettingsMutation,
} from './api';
import {MAX_AMOUNT, TFormData} from './definitions';
import * as S from './style';
import {useAutopaymentRecommendedThreshold} from './useAutopaymentRecommendedThreshold';

type TValidatorProps = {minThreshold?: number; minAmount?: number; currency: TCurrency};

const makeValidator = ({currency, minThreshold = 0, minAmount = 0}: TValidatorProps) =>
  joiResolver((joi) =>
    joi.object({
      threshold: joi
        .number()
        .required()
        .max(MAX_AMOUNT)
        .custom((value, helpers) => {
          if (Number(value) < minThreshold) {
            return helpers.error('minThreshold', {currency, limit: minThreshold});
          }

          return value;
        }),
      amount: joi
        .number()
        .required()
        .max(MAX_AMOUNT)
        .custom((value, helpers) => {
          if (Number(value) < minAmount) {
            return helpers.error('minAmount', {currency, limit: minAmount});
          }

          return value;
        }),
    }),
  );

type TModalProps = {
  isConnect?: boolean;
  closeModal: TEmptyFunction;
};

const AutopaymentSettingsModal = React.memo<TModalProps>(({closeModal, isConnect}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const {data: supportClient} = useGetSupportClientQuery(supportTenantId ?? skipToken);
  const {data: info} = useGetAutopaymentInfoQuery();
  const {data: restrictions} = useGetAutopaymentRestrictionsQuery();
  const {data: tenantData} = useGetTenantDataQuery();
  const [connectAutopayment, {isLoading: isConnecting}] = useConnectAutopaymentMutation();
  const [updateAutopaymentSettings, {isLoading: isUpdating}] =
    useUpdateAutopaymentSettingsMutation();

  const recommendedThreshold = useAutopaymentRecommendedThreshold();

  const currency =
    (supportTenantId ? supportClient?.defaultCurrency : tenantData?.defaultCurrency) ??
    ECurrency.USD;

  const defaultValues = React.useMemo(
    () => ({
      threshold:
        info?.threshold ?? Math.max(recommendedThreshold, restrictions?.minimumThreshold ?? 0),
      amount: info?.amount ?? restrictions?.minimumAmount,
    }),
    [restrictions, info, recommendedThreshold],
  );

  const resolver = React.useMemo(
    () =>
      makeValidator({
        minAmount: restrictions?.minimumAmount,
        minThreshold: restrictions?.minimumThreshold,
        currency,
      }),
    [restrictions, currency],
  );

  const methods = useForm<TFormData>({
    mode: 'all',
    defaultValues,
    resolver,
  });

  useOnChange(() => methods.reset(defaultValues), [defaultValues]);

  const amountError = methods.formState.errors.amount;
  const thresholdError = methods.formState.errors.threshold;

  const handleSave = React.useCallback(async () => {
    const values = methods.getValues();

    try {
      if (isConnect) {
        const result = await connectAutopayment(values).unwrap();

        if (result?.stripeUrl) {
          window.open(result.stripeUrl, '_blank', 'noreferrer');
        }
      } else {
        await updateAutopaymentSettings(values).unwrap();
      }

      closeModal();
    } catch (e) {}
  }, [closeModal, methods.getValues, isConnect]);

  const threshold = methods.watch('threshold');

  const isShowWarning = React.useMemo(() => {
    if (thresholdError || !threshold) {
      return false;
    }

    return Number(threshold) < recommendedThreshold;
  }, [threshold, recommendedThreshold, thresholdError]);

  return (
    <Modal width="582px">
      <FormProvider {...methods}>
        <ModalHeader>
          {t(`Autopayment:settingsModal.${isConnect ? 'titleConnect' : 'titleEdit'}`)}
        </ModalHeader>
        <ModalContent>
          <S.Wrapper>
            {!!recommendedThreshold && (
              <SecondaryText size="S">
                <Trans
                  i18nKey="Autopayment:settingsModal.licensesInfo"
                  values={{threshold: recommendedThreshold, currency}}
                >
                  <Text size="S" weight={500} isInline />
                </Trans>
              </SecondaryText>
            )}
            <SectionWithBorderField
              name="threshold"
              size="2"
              label={t('Autopayment:settingsModal.threshold')}
              htmlFor="threshold"
            >
              <Stack size="2" direction="ROW" align="center">
                <S.NumberInputField name="threshold" showError={false} />
                <Text size="S">{currency}</Text>
              </Stack>
              {!!thresholdError && (
                <FieldError>
                  {t(thresholdError?.message ?? '', thresholdError?.ref?.value)}
                </FieldError>
              )}
              <Hint>
                {t('Autopayment:settingsModal.minThreshold', {
                  currency,
                  threshold: restrictions?.minimumThreshold,
                })}
              </Hint>
            </SectionWithBorderField>
            <SectionWithBorderField
              name="amount"
              size="2"
              label={t('Autopayment:settingsModal.amount')}
              htmlFor="amount"
            >
              <Stack size="2" direction="ROW" align="center">
                <S.NumberInputField name="amount" showError={false} />
                <Text size="S">{currency}</Text>
              </Stack>
              {!!amountError && (
                <FieldError>{t(amountError?.message ?? '', amountError?.ref?.value)}</FieldError>
              )}
              <Hint>
                {t('Autopayment:settingsModal.minAmount', {
                  currency,
                  amount: restrictions?.minimumAmount,
                })}
              </Hint>
            </SectionWithBorderField>
          </S.Wrapper>
          {isShowWarning && (
            <Message type="Warning" fontSize="S">
              {t('Autopayment:settingsModal.incorrectSettingsWarning')}
            </Message>
          )}
          <SecondaryText size="S">{t('Autopayment:settingsModal.acceptanceInfo')}</SecondaryText>
        </ModalContent>
        <ModalActions>
          <ModalCancelButton onClick={closeModal}>{t('Common:button.cancel2')}</ModalCancelButton>
          <ModalSubmitButton
            onClick={methods.handleSubmit(handleSave)}
            disabled={
              !methods.formState.isDirty || !methods.formState.isValid || isConnecting || isUpdating
            }
          >
            {t('Common:button.apply')}
          </ModalSubmitButton>
        </ModalActions>
      </FormProvider>
    </Modal>
  );
});

AutopaymentSettingsModal.displayName = 'AutopaymentSettings';

const showAutopaymentSettingsModal = (isConnect?: boolean) =>
  showModal((closeModal) => (
    <AutopaymentSettingsModal isConnect={isConnect} closeModal={closeModal} />
  ));

export {showAutopaymentSettingsModal};
