import {
  EMessagingLimit,
  EQualityScore,
  EQualityStatus,
  EWabaStatus,
} from 'src/containers/Channels/definitions';

export type TState = {
  logoFile: TFile | null;
  resultOfCheckPhone: {
    loading: boolean;
    valid: boolean;
    operator?: string | null;
    region?: string | null;
  };
  facebookManagerData: {
    loading: boolean;
    valid: boolean;
    id?: string | number;
  };
  verificationLoading: boolean;
  initialValues: TFormData;
};

export enum EStatus {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  NEW = 'NEW',
  CREATE_ENVIRONMENT = 'CREATE_ENVIRONMENT',
  CONFIRM_CODE_REQUEST = 'CONFIRM_CODE_REQUEST',
  SEND_CONFIRM_CODE = 'SEND_CONFIRM_CODE',
  FINISH_REGISTER = 'FINISH_REGISTER',
  CREATE_WHATSAPP_ACCOUNT = 'CREATE_WHATSAPP_ACCOUNT',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
  NEED_CONFIRM_ACCOUNT = 'NEED_CONFIRM_ACCOUNT',
  NEED_VERIFY_FACEBOOK_BUSINESS = 'NEED_VERIFY_FACEBOOK_BUSINESS',
  NEED_CONFIRM_CODE = 'NEED_CONFIRM_CODE',
  DONE_VERIFIED = 'DONE_VERIFIED',
  DONE_NOT_VERIFIED = 'DONE_NOT_VERIFIED',
  CANCELED = 'CANCELED',
  BLOCKED = 'BLOCKED',
  UNKNOWN = 'UNKNOWN',
}

export type TStatus = keyof typeof EStatus;

type TValue = {value: string};

export const INITIAL_VALUES: TFormData = {
  name: '',
  address: '',
  businessManagerId: '',
  alternativeNames: [{value: ''}],
  businessKind: '',
  contactStatus: '',
  description: '',
  email: '',
  facebookPageUrl: '',
  logoFile: null,
  phone: '',
  phoneCode: '',
  senderName: '',
  status: EStatus.DRAFT,
  statusComment: '',
  urls: [{value: ''}],
  communicationSolution: null,
  tariffTemplateId: null,
};

export const getInitialValues = (item: TWhatsAppChannel | null = null) => {
  if (item === null) {
    return INITIAL_VALUES;
  }

  return {
    id: item.id,
    name: item.name,
    address: item.address,
    alternativeNames: item.alternativeNames?.map((name) => ({value: name})) || [{value: ''}],
    businessKind: item.businessKind,
    contactStatus: item.contactStatus,
    description: item.description,
    email: item.email,
    facebookPageUrl: item.facebookPageUrl,
    logoFile: item.logoFile,
    phone: item.phone,
    phoneCode: item.phoneCode ? item.phoneCode.replace(/\D+/g, '') : item.phoneCode,
    senderName: item.senderName,
    urls: item.urls?.map((url) => ({value: url})) || [{value: ''}],
    communicationSolution: item.communicationSolution,
    status: item.status,
    tariffTemplateId: item.tariffTemplateId,
  };
};

export enum ECommunicationSolution {
  API = 'API',
  Jivochat = 'Jivochat',
}

export enum EVerificationMethods {
  SMS = 'SMS',
  VOICE = 'VOICE',
}

export type TVerificationFormData = {
  id?: number | string;
  method?: EVerificationMethods | null;
  code?: string | null;
};

export type TWhatsAppChannel = {
  id: number;
  name: string | null;
  address: string | null;
  alternativeNames: string[];
  businessKind: string | null;
  businessManagerId: number | string;
  contactStatus: string | null;
  description: string | null;
  email: string | null;
  facebookPageUrl: string | null;
  logoFile: TFile | null;
  phone: string | null;
  phoneCode: string | null;
  senderName: string | null;
  status: EStatus;
  statusComment: string | null;
  urls: string[];
  createdAt: string;
  updatedAt: string;
  communicationSolution: keyof typeof ECommunicationSolution | null;
  tariffTemplateId: number | null;
  // quality
  qualityScore: EQualityScore | null;
  messagingLimit: EMessagingLimit | null;
  previousQualityScore: EQualityScore | null;
  previousMessagingLimit: EMessagingLimit | null;
  qualityUpdatedAt: string;
  qualityStatus: EQualityStatus | null;
  // WABA restrictions
  wabaId?: number;
  wabaViolationType?: string;
  wabaRestrictionExpirationDate?: string;
  wabaStatus?: EWabaStatus;
};

export type TWhatsAppFormData = {
  managerId: number | string;
};

export type TFormData = {
  id?: TWhatsAppChannel['id'];
  businessManagerId?: TWhatsAppChannel['businessManagerId'];
  name: TWhatsAppChannel['name'];
  address: TWhatsAppChannel['address'];
  alternativeNames: TWhatsAppChannel['alternativeNames'] | TValue[];
  businessKind: TWhatsAppChannel['businessKind'];
  contactStatus: TWhatsAppChannel['contactStatus'];
  description: TWhatsAppChannel['description'];
  email: TWhatsAppChannel['email'];
  facebookPageUrl: TWhatsAppChannel['facebookPageUrl'];
  logoFile: TWhatsAppChannel['logoFile'];
  phone: TWhatsAppChannel['phone'];
  phoneCode: TWhatsAppChannel['phoneCode'];
  senderName: TWhatsAppChannel['senderName'];
  urls: TWhatsAppChannel['urls'] | TValue[];
  communicationSolution: TWhatsAppChannel['communicationSolution'];
  status: TWhatsAppChannel['status'];
  tariffTemplateId?: TWhatsAppChannel['tariffTemplateId'];
  statusComment?: TWhatsAppChannel['statusComment'];
};

export type TFacebookManagerData = {
  correctFBManagerId: boolean;
  companyName: string;
  error: string;
};

export type TPhoneData = {
  phoneCode: string;
  phoneNumber: string;
  operator: string;
  region: string;
};

export const BUSINESS_KINDS = [
  'Automotive',
  'Beauty, Spa and Salon',
  'Clothing and Apparel',
  'Education',
  'Entertainment',
  'Event Planning and Service',
  'Finance and Banking',
  'Food and Grocery',
  'Public Service',
  'Hotel and Lodging',
  'Medical and Health',
  'Non-profit',
  'Professional Services',
  'Shopping and Retail',
  'Travel and Transportation',
  'Restaurant',
  'Other',
];

export const NOT_FOUND_MANAGER_ID_ERROR = 'whatsapp-registrar-rest-service.EXTERNAL_ERROR';

export enum ESolutionIdApproveStatus {
  ERROR = 'ERROR',
  DONE = 'DONE',
}

export type TRegisterSolutionIdResponse = {
  id: number;
  solutionId: string;
  tenantId: number;
  approveStatus: ESolutionIdApproveStatus;
  createdAt: string;
  updatedAt: string;
  statusComment: string;
};
