import {ECurrency, TCurrency} from '@edna/utils';

export type TCompanyDetails = {
  country: string;
  legalAddress: string;
  postAddress: string;
  taxNumber: string;
  ogrn?: string;
  authorizedPersonName?: string;
  registrationEmail?: string;
  vatNumber?: string;
  kpp?: string;
  companyName?: string;
  siteAddress?: string;
};

export type TPaymentDetails = {
  country: string;
  bankName: string;
  corrBankAccount?: string;
  bankAddress?: string;
  iban?: string;
  bic: string;
  operBankAccount?: string;
};

export type TTenantLeadDetails = {
  id: number;
  companyName: string;
  currencyCode: TCurrency;
  countryCode: string;
  paymentType?: string;
  ogrn?: string;
  documentType: string;
  taxNumber: string;
  resellerId: number;
  contactName: string;
  email: string;
  phone: string;
  responsibleManagerId: number;
  maxOverdraft: number;
  securityDeposit: number;
  activateLicenseDebt: boolean;
  licensePaymentTerm: number;
  termBeforeTerminationLicense: number;
  managerResponsibleForCheckingId?: number;
  billingDisabled: boolean;
};

export enum EDetailsAction {
  FILL = 'FILL',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

export enum EResidentCountry {
  RUS = 'RUS',
  BLR = 'BLR',
  FOREIGN = 'FOREIGN',
}

export const DEFAULT_COMPANY_DETAILS: TCompanyDetails = {
  country: '',
  legalAddress: '',
  postAddress: '',
  taxNumber: '',
};

export const DEFAULT_PAYMENT_DETAILS: TPaymentDetails = {
  country: '',
  bankName: '',
  corrBankAccount: '',
  bic: '',
  operBankAccount: '',
};

export const DEFAULT_TENANT_LEAD_DETAILS: TTenantLeadDetails = {
  id: -1,
  resellerId: -1,
  companyName: '',
  currencyCode: ECurrency.RUB,
  countryCode: '',
  documentType: '',
  taxNumber: '',
  contactName: '',
  email: '',
  phone: '',
  responsibleManagerId: -1,
  maxOverdraft: 0,
  securityDeposit: 0,
  activateLicenseDebt: false,
  licensePaymentTerm: 0,
  termBeforeTerminationLicense: 0,
  ogrn: '',
  paymentType: '',
  billingDisabled: true,
};
