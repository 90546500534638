import {addMonths} from 'date-fns/addMonths';
import {startOfMonth} from 'date-fns/startOfMonth';

import {and, formatDate} from '@edna/utils';

import {EChannelType, TItemId, channelTypeHash} from 'src/constants';
import {EPermission} from 'src/containers/User';
import {ECacheTag, rootApi} from 'src/models';

import {TNextPaymentItems, TPaymentDebtItems} from './PaymentTab/definitions';
import {TLicense, TLicenseStatus} from './definitions';

type TNextPaymentPayload = {
  tenantId?: TItemId;
  additionalMonths?: number;
  statuses?: TLicenseStatus[];
};

export const licenseApi = rootApi.injectEndpoints({
  endpoints: (builder) => {
    const makePaymentDebtsQuery = (channelType?: EChannelType) =>
      builder.query<TPaymentDebtItems, TItemId | void>({
        query: (tenantId) => ({
          method: 'GET',
          url: tenantId
            ? '/admin/billing/license/payment/debt/groups'
            : '/billing/license/payment/debt/groups',
          params: {
            channelType: channelType ? channelTypeHash[channelType].toUpperCase() : undefined,
            tenantId,
          },
          meta: {
            isShowError: true,
            permissions: tenantId
              ? and<EPermission>('PERMISSION_TENANT_TRANSACTION_READ')
              : and<EPermission>('PERMISSION_TRANSACTION_READ'),
          },
        }),
      });

    const makeNextPaymentQuery = (channelType?: EChannelType) =>
      builder.query<TNextPaymentItems, TNextPaymentPayload | void>({
        query: ({tenantId, additionalMonths, statuses} = {}) => ({
          method: 'POST',
          url: tenantId
            ? '/admin/billing/license/payment/next/groups'
            : '/billing/license/payment/next/groups',
          params: {
            channelType: channelType ? channelTypeHash[channelType].toUpperCase() : undefined,
            tenantId,
          },
          data: {
            period: formatDate(startOfMonth(addMonths(new Date(), additionalMonths ?? 1)), {
              format: 'yyyy-MM',
            }),
            statuses,
          },
          meta: {
            isShowError: true,
            permissions: tenantId
              ? and<EPermission>('PERMISSION_TENANT_TRANSACTION_READ')
              : and<EPermission>('PERMISSION_TRANSACTION_READ'),
          },
        }),
      });

    return {
      getAllPaymentDebts: makePaymentDebtsQuery(),
      getSmsPaymentDebts: makePaymentDebtsQuery(EChannelType.SMS),
      getWhatsappPaymentDebts: makePaymentDebtsQuery(EChannelType.WHATSAPP),
      getViberPaymentDebts: makePaymentDebtsQuery(EChannelType.VIBER),

      getAllNextPayment: makeNextPaymentQuery(),
      getSmsNextPayment: makeNextPaymentQuery(EChannelType.SMS),
      getWhatsappNextPayment: makeNextPaymentQuery(EChannelType.WHATSAPP),
      getViberNextPayment: makeNextPaymentQuery(EChannelType.VIBER),

      getAllLicenses: builder.query<TLicense[], TItemId | void>({
        query: (tenantId) => ({
          method: 'GET',
          url: tenantId ? '/admin/billing/license' : '/billing/license',
          params: {
            tenantId,
          },
          meta: {
            isShowError: true,
            permissions: tenantId
              ? and<EPermission>('PERMISSION_TENANT_LICENSE_READ')
              : and<EPermission>('PERMISSION_READ'),
          },
        }),
        providesTags: [ECacheTag.LICENSES],
      }),
      cancelLicense: builder.mutation<void, {licenseId: TLicense['id']; tenantId?: TItemId}>({
        query: ({licenseId, tenantId}) => ({
          method: 'PUT',
          url: tenantId
            ? `/admin/billing/license/${licenseId}/cancel`
            : `/billing/license/${licenseId}/cancel`,
          params: {
            tenantId,
          },
          meta: {
            isShowError: true,
            isShowSuccess: true,
            successMessageKey: 'Licenses:cancelModal.successBlock',
            permissions: tenantId
              ? and<EPermission>('PERMISSION_TENANT_LICENSE_BLOCK')
              : and<EPermission>('PERMISSION_LICENSE_BLOCK'),
          },
        }),
        invalidatesTags: [ECacheTag.LICENSES],
      }),
    };
  },
});

export const {
  useGetAllPaymentDebtsQuery,
  useGetSmsPaymentDebtsQuery,
  useGetWhatsappPaymentDebtsQuery,
  useGetViberPaymentDebtsQuery,

  useGetAllNextPaymentQuery,
  useGetSmsNextPaymentQuery,
  useGetWhatsappNextPaymentQuery,
  useGetViberNextPaymentQuery,

  useGetAllLicensesQuery,
  useCancelLicenseMutation,
} = licenseApi;
