import React from 'react';
import {Transition, animated} from 'react-spring';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import {useTheme} from 'styled-components';

import {newFormField} from '@edna/components/FormField/hoc';
import {easing, getVerticalPosition} from '@edna/components/utils';
import {useEvent} from '@edna/hooks';
import {mergeRefs} from '@edna/utils';

import {DEFAULT_DEBOUNCE_DELAY} from 'src/constants';

import Options from './Options';
import * as S from './style';
import {TNode, TPosition, TProps, TState, TTreeMap} from './types';

const AnimatedOverlay = animated(S.Overlay);

const debouncedCall = debounce((callback) => callback(), DEFAULT_DEBOUNCE_DELAY);

const TreeSelect = React.memo<TProps>(
  ({
    treeMap = {},
    disabled = false,
    placementY = 'bottom',
    className,
    isInvalid,
    placeholder,
    value,
    onChange = noop,
    onFocus = noop,
    onBlur = noop,
    onSearch,
    searchPlaceholder,
    innerRef,
  }) => {
    const headRef = React.useRef<HTMLDivElement>(null);
    const searchInputRef = React.useRef<HTMLInputElement>(null);
    const overlayRef = React.useRef<HTMLDivElement>(null);
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const [isOpen, setIsOpen] = React.useState<TState['isOpen']>(false);
    const [search, setSearch] = React.useState<TState['search']>('');
    const [position, setPosition] = React.useState<TState['position']>({} as TPosition);
    const isPlacementBottom = placementY === 'bottom';

    const toggleOpen = React.useCallback(() => {
      if (disabled) {
        return;
      }
      setIsOpen(!isOpen);
      const headNode = headRef.current;

      if (!!headNode && isOpen) {
        setPosition(getVerticalPosition(headNode, placementY));
        searchInputRef.current?.focus();
      }
    }, [disabled, headRef, isOpen, placementY]);

    const handleDocumentClick = React.useCallback(
      (event: Event) => {
        if (!document.documentElement.contains(event.target as Node) || !isOpen) {
          return;
        }
        const overlayNode = overlayRef.current;
        const headNode = headRef.current;

        if (
          !headNode?.contains(event.target as Node) &&
          !overlayNode?.contains(event.target as Node)
        ) {
          toggleOpen();
        }
      },
      [headRef, isOpen, toggleOpen],
    );

    useEvent('click', handleDocumentClick);

    const handleInputChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value: searchValue} = event.target;

        setSearch(searchValue);

        if (onSearch) {
          debouncedCall(() => onSearch(searchValue));
        }
      },
      [onSearch],
    );

    const handleFocus = React.useCallback(
      (event: React.FocusEvent<HTMLElement>) => {
        if (!disabled && isFunction(onFocus)) {
          onFocus(event);
        }
      },
      [disabled, onFocus],
    );

    const handleBlur = React.useCallback(
      (event: React.FocusEvent<HTMLElement>) => {
        const relatedTarget = event.relatedTarget as Node;
        const headNode = headRef.current;

        if (
          !disabled &&
          isFunction(onBlur) &&
          !searchInputRef.current?.contains(relatedTarget) &&
          !overlayRef.current?.contains(relatedTarget) &&
          !headNode?.contains(relatedTarget)
        ) {
          onBlur(event);
        }
      },
      [disabled, headRef, onBlur],
    );

    const renderValues = () => {
      if (!value) {
        if (placeholder !== undefined) {
          return <S.Placeholder>{placeholder}</S.Placeholder>;
        }

        return null;
      }

      return (
        <S.Value disabled={disabled}>
          <S.ValueLabel>{treeMap[value].name}</S.ValueLabel>
        </S.Value>
      );
    };

    const renderSearchInput = () => {
      if (isEmpty(treeMap)) {
        return null;
      }

      return (
        <S.SearchInput>
          <S.Input
            ref={searchInputRef}
            placeholder={searchPlaceholder}
            onChange={handleInputChange}
            value={search}
          />
          <S.SearchIcon />
        </S.SearchInput>
      );
    };

    return (
      <S.Select className={className} onFocus={handleFocus} onBlur={handleBlur}>
        <S.Head
          ref={mergeRefs(headRef, innerRef)}
          onClick={toggleOpen}
          isOpen={isOpen}
          isPlacementBottom={isPlacementBottom}
          isInvalid={isInvalid}
          disabled={disabled}
          tabIndex={-1}
        >
          {renderValues()}
          <S.ArrowIconWrapper>
            <S.ArrowIcon open={isOpen} />
          </S.ArrowIconWrapper>
        </S.Head>
        <Transition
          items={isOpen}
          from={{opacity: 0, y: isPlacementBottom ? -10 : 10}}
          enter={{opacity: 1, y: 0}}
          leave={{opacity: 0}}
          config={{
            duration: theme.animation.duration,
            easing,
          }}
        >
          {(style, open) => {
            if (!open) {
              return null;
            }

            return (
              <AnimatedOverlay
                tabIndex={0}
                position={position}
                ref={overlayRef}
                isPlacementBottom={isPlacementBottom}
                style={style}
              >
                <>
                  {isPlacementBottom && renderSearchInput()}
                  <S.SearchOptionsWrapper ref={scrollContainerRef}>
                    <Options
                      treeMap={treeMap}
                      onChange={onChange}
                      search={search}
                      setSearch={setSearch}
                      toggleOpen={toggleOpen}
                    />
                  </S.SearchOptionsWrapper>
                  {!isPlacementBottom && renderSearchInput()}
                </>
              </AnimatedOverlay>
            );
          }}
        </Transition>
      </S.Select>
    );
  },
);

TreeSelect.displayName = 'TreeSelect';

export default TreeSelect;

export const TreeSelectField = newFormField<TProps>(TreeSelect);

export type {TNode, TTreeMap};
