import {EWabaStatus} from '../Channels/definitions';
import {EFormSteps, EPublicStatus, EStatus, LOGO_SIZE} from './definitions';

const activeStatus = {
  title: 'Active',
  messageTitle: 'Active',
  messageContent:
    'Your channel has been registered. Now you can use it to communicate with your customers. ' +
    'To increase the daily channel limit to 1,000 messages and display the account name in customer chats, follow one of the recommended conditions:',
  reason1: 'Send 1,000 template messages within 30 days.',
  reason2: 'Verify the business in Meta. ',
  link: 'Verification instructions.',
};

export default {
  steps: {
    [EFormSteps.SETTINGS]: 'Registration requirements and channel settings',
    [EFormSteps.VIEW]: 'Account representation',
  },
  clarifyForm: {
    title: '{{name}} channel data',
    confirmTitle: 'Save changes?',
    confirmMessage: 'Channel pricing and internal channel name cannot be changed after saving',
    cancelTitle: 'Cancel changes?',
    cancelMessage:
      'Save the selected channel pricing so that the customer\'s channel switches to the "Correct" status',
  },
  editForm: {
    name: 'Internal channel name',
    nameHint: 'This name will be displayed in the list of channels in edna Pulse.',
    tariff: 'Channel pricing',
    address: 'Company address',
    email: 'Company e-mail address',
    status: 'WhatsApp account status',
    logo: 'Company logo',
    logoHint: `Your company logo in JPG or JPEG format, with a minimum size of ${LOGO_SIZE}×${LOGO_SIZE}px. You can edit this parameter through edna technical support after registering the channel.`,
    baseHint:
      'You can edit this parameter through edna technical support after registering the channel.',
    settingsBlock: 'Channel settings',
    viewBlock: 'Account representation',
    viewBlockHint:
      'Your customers will see this information when viewing the profile of this WhatsApp account.',
    phone: 'Phone number to which the channel is registered',
    phoneHint: 'This phone number is provided when registering your WhatsApp Business Account.',
    facebookHint:
      'To register a channel with WhatsApp Business Account, please log into your Facebook account and follow the suggested steps.',
  },
  techPartner: {
    code: 'Registration link',
    codeHint:
      'Copy and send this link to the customer to complete the WhatsApp channel registration',
  },
  regenerateCodeModal: {
    title: 'Update registration link?',
    subTitle: 'The current link becomes invalid and a new one is created after the update',
  },
  publicForm: {
    title:
      'This page allows you to register a WhatsApp channel. Your provider {{partnerName}} will receive information about the registration',
    helpTitle: 'Conditions required for registration:',
    helpSteps: [
      'You have a personal Facebook account.',
      "You have a phone number to register a WhatsApp account. The number shouldn't be associated with any other WhatsApp accounts.",
      'You can receive an SMS message or accept an international call to the specified phone number to confirm it.',
    ],
    successTitle: 'Channel sent for registration',
    successHint:
      'To check the registration status, contact your provider <br/><0>{{partnerName}}</0>',
    errorTitle: 'WhatsApp channel registration is not available at this link:<br/><0>{{error}}</0>',
    errorHint: 'To get a new link, contact your provider<br/><0>{{partnerName}}</0>',
    errors: {
      [EPublicStatus.ERROR_USED_CODE]: 'channel is already registered at this link',
      [EPublicStatus.ERROR_INCORRECT_TARIFF]:
        'wrong connection conditions specified for the channel',
      [EPublicStatus.ERROR_OLD_CODE]: 'the link has expired',
      unknown: "this page doesn't exist",
    },
    facebookTerms: 'By clicking Continue with Facebook, you agree to the <0>terms of service</0>',
  },
  adblockStatus: {
    info: "You've disabled pop-up blocking extensions or browser settings.",
    adblockDetected:
      'An Adblock extension is enabled on your device to block pop-ups. To continue, disable the extension.',
    popupsDetected:
      "It's possible that your browser has pop-up blocking or an Adblock extension enabled. To continue, change your browser settings or disable the extension.",
    notDetected:
      "We haven't found any blocking settings enabled. If the Facebook window doesn't open, check your browser settings and extensions.",
    retry: 'Recheck',
  },
  facebookAvailability: {
    info: 'Your device opens the Facebook website.',
    error:
      'Your device can’t open the Facebook website.\nTo continue, establish a connection with the website',
    loading: 'Establishing a connection with the website',
    retry: 'Recheck',
  },
  imageErrors: {
    invalidLogoFormat: 'Invalid logo format',
  },
  status: {
    [EStatus.UI_NEED_TARIFF_TEMPLATE_ID]: {
      title: 'Requires attention',
      messageTitle: 'Select channel pricing',
      messageContent:
        'Your customer {{name}} has submitted the channel for registration. To complete the registration, select the channel pricing. You can change the internal channel name before saving the channel pricing.',
    },
    [EStatus.DRAFT]: {title: 'Draft'},
    [EStatus.NEW]: {title: 'New'},
    [EStatus.DELETED]: {title: 'Disabled'},
    [EStatus.DONE_BUSINESS_VERIFICATION]: activeStatus,
    [EStatus.BUSINESS_VERIFICATION_EXPIRED]: activeStatus,
    [EStatus.DONE_FINISH_REGISTER]: {
      title: 'Active',
      messageTitle: 'Active',
      messageContent:
        "You've verified your business in Meta for this WhatsApp account, and your channel has been registered. You now have access to increased daily limits for sending template messages and displaying your account name in customer chats.",
    },
    [EStatus.SENT_TO_PLATFORM]: {
      title: 'Pending registration',
      messageTitle: 'Pending registration',
      messageContent: 'Your channel is being registered on Facebook and will be available soon.',
    },
    [EStatus.CANCELLED]: {
      title: 'Disabled',
      messageTitle: 'Disabled',
    },
    [EStatus.DISABLED]: {
      title: 'Disabled',
      messageTitle: 'Disabled',
    },
  },
  wabaStatus: {
    [EWabaStatus.ACCOUNT_RESTRICTION]: {
      messageTitle: 'Channel is limited (WABA)',
      messageContent:
        'WhatsApp Business account {{wabaId}} to which this channel belongs has been restricted due to activity that violates the WhatsApp Business Terms of Service. {{reason}}<b>Outgoing messages cannot be sent until {{expirationDate}}</b>. The channel is only available to receive incoming messages.' +
        '<br/><br/>To submit an appeal, <0>follow the recommendations</0>.',
      wabaRestrictionReason: 'The reason is {{violationType}}. ',
    },
    [EWabaStatus.DISABLED]: {
      messageTitle: 'Disabled',
      messageContent:
        'WhatsApp Business account {{wabaId}} to which this channel belongs has been disabled due to activity that violates the WhatsApp Business Terms of Service. The channel has stopped working.' +
        '<br/><br/>To submit an appeal, <0>follow the recommendations</0>.',
    },
  },
};
