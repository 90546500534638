import styled, {css} from 'styled-components';

import {Button, TSize} from '@edna/components';
import {mixins} from '@edna/components/styles';
import {hexToRgba} from '@edna/utils';

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const EditButton = styled(Button).attrs({
  square: true,
  appearance: 'secondary',
})`
  ${mixins.position({position: 'absolute', top: '12px', right: '12px'})}
`;

type TContentProps = TTheme & {
  isInvalid?: boolean;
  isFull?: boolean;
  width?: number;
  minHeight?: number;
  disabled: boolean;
  readOnly: boolean;
};

const getContentStyle = ({isFull, width, minHeight, theme}: TContentProps) => css`
  min-height: ${`${minHeight}px`};
  ${!isFull && mixins.wh(`${width}px`)}
  background-color: ${theme.branding.backgrounds.secondary};
  padding: ${isFull ? theme.spacing.s6 : 0};
  border-radius: ${theme.borderRadius.br2};
  transition: box-shadow ${theme.animation.duration}ms;
  box-shadow:
    inset 0 -2px 9px ${hexToRgba(theme.branding.content.primary, '6%')},
    inset 0 1px 4px ${hexToRgba(theme.branding.content.primary, '10%')};
`;

const getContentDisabledStyle = ({theme, disabled, readOnly}: TContentProps) =>
  !disabled &&
  !readOnly &&
  css`
    &:hover {
      box-shadow:
        0 3px 12px -1px ${hexToRgba(theme.branding.content.primary, '26%')},
        0 2px 4px -1px ${hexToRgba(theme.branding.content.primary, '16%')};
    }
  `;

const getContentInvalidStyle = ({theme, isInvalid}: TContentProps) =>
  isInvalid &&
  css`
    border: 1px solid ${theme.branding.colors.error};
    background-color: ${theme.branding.backgrounds.error};
  `;

export const Content = styled.div<TContentProps>`
  ${mixins.flexAlign('center')}
  position: relative;
  flex-direction: column;
  overflow: hidden;
  ${getContentStyle}
  ${getContentDisabledStyle}
  ${getContentInvalidStyle}
`;

type TImagePickerProps = {
  disabled: boolean;
  readOnly: boolean;
};

const getImagePickerCursorStyle = ({disabled, readOnly}: TImagePickerProps) => {
  if (disabled) {
    return 'not-allowed';
  }

  if (readOnly) {
    return 'auto';
  }

  return 'pointer';
};

export const ImagePicker = styled.label<TImagePickerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({disabled, readOnly}) => css`
    cursor: ${getImagePickerCursorStyle({disabled, readOnly})};
    opacity: ${disabled ? 0.64 : 1};
  `}
`;

export const PlaceHolder = styled.div`
  ${mixins.f2}
  text-align: center;

  ${({theme}) => css`
    margin-top: ${theme.spacing.s2};
    color: ${theme.branding.content.primary};
  `}
`;

export const AddIcon = styled.svg`
  ${mixins.wh('48px')}
  display: flex;

  ${({theme}) => css`
    margin-bottom: ${theme.spacing.s1};
    color: ${theme.branding.content.tertiary};
  `}
`;

type TAcceptProps = {
  size?: TSize;
};

export const Accept = styled.div<TAcceptProps>`
  ${({size}: TAcceptProps) => (size === 'S' ? mixins.f4 : mixins.f6)}

  ${({theme}) => css`
    margin-bottom: ${theme.spacing.s2};
    color: ${theme.branding.content.tertiary};
  `}
`;
