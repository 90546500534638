import {TPeriodPickerValue} from '@edna/components';

import {TAutopaymentError} from 'src/containers/Autopayment';
import {EMessagingLimit, EQualityScore, EQualityStatus} from 'src/containers/Channels/definitions';
import {EWhatsAppCategory} from 'src/containers/MessageMatchers/WhatsAppForm/definitions';
import {EApproveStatus} from 'src/containers/MessageMatchers/definitions';

export enum EStatus {
  UNREAD = 'UNREAD',
  SHOWED = 'SHOWED',
  READ = 'READ',
}

export type TStatus = keyof typeof EStatus;

export enum EWebNotificationType {
  SubscribersUploadSuccess = 'SubscribersUploadSuccess',
  SubscribersUploadPartial = 'SubscribersUploadPartial',
  SubscribersUploadFailed = 'SubscribersUploadFailed',
  SubscribersUploadLoading = 'SubscribersUploadLoading',

  BroadcastCompleted = 'BroadcastCompleted',
  BroadcastCancelled = 'BroadcastCancelled',

  LicenseExpiring = 'LicenseExpiring',

  CallbackUpdated = 'CallbackUpdated',

  ApikeyUserUpdated = 'ApikeyUserUpdated',

  CompanyDetailsUpdated = 'CompanyDetailsUpdated',
  PaymentDetailsUpdated = 'PaymentDetailsUpdated',
  TenantTariffAccepted = 'TenantTariffAccepted',
  AgreedChangeTariffRequest = 'AgreedChangeTariffRequest',
  NotAgreedChangeTariffRequest = 'NotAgreedChangeTariffRequest',
  JivoEnabled = 'JivoEnabled',
  JivoDisabled = 'JivoDisabled',

  MessageMatcherStatusChanged = 'MessageMatcherStatusChanged',
  MessageMatcherCategoryChanged = 'MessageMatcherCategoryChanged',

  InvoicePaid = 'InvoicePaid',
  StripeAutopayment = 'StripeCheckout',

  ChannelProfileQualityScoreChanged = 'ChannelProfileQualityScoreChanged',
  ChannelProfileMessagingLimitChanged = 'ChannelProfileMessagingLimitChanged',
  ChannelProfileQualityStatusChanged = 'ChannelProfileQualityStatusChanged',
  ChannelProfileWABAViolation = 'ChannelProfileWABAViolation',
  ChannelProfileWABARestriction = 'ChannelProfileWABARestriction',
  ChannelProfileWABADisable = 'ChannelProfileWABADisable',
  ChannelProfileWABAReinstate = 'ChannelProfileWABAReinstate',
}

export enum ELevel {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

export type TLevel = keyof typeof ELevel;

export enum ECategory {
  SUBSCRIBERS_UPLOAD = 'SUBSCRIBERS_UPLOAD',
  BROADCAST_LIFECYCLE = 'BROADCAST_LIFECYCLE',
  LICENSE = 'LICENSE',
  CALLBACK_LIFECYCLE = 'CALLBACK_LIFECYCLE',
  APIKEY_USER_LIFECYCLE = 'APIKEY_USER_LIFECYCLE',
  TENANT_DETAILS_UPDATES = 'TENANT_DETAILS_UPDATES',
  TARIFF_ACCEPTANCE = 'TARIFF_ACCEPTANCE',
  TARIFF_CHANGE = 'TARIFF_CHANGE',
  TARIFF_REJECTION = 'TARIFF_REJECTION',
}

export enum ESystemNotificationEntityType {
  NOTIFICATION = 'NOTIFICATION',
  NEWS = 'NEWS',
}

type TGenericNotification = {
  id: number;
  status: TStatus;
  level: TLevel;
  occurredAt: string;
};

type TGenericSystemNotification = TGenericNotification & {
  entityType: ESystemNotificationEntityType;
};

export type TChannelProfileQualityStatusChangedNotification = TGenericNotification & {
  type: EWebNotificationType.ChannelProfileQualityStatusChanged;
  meta: {
    whatsAppProfileQualityStatusChanged: {
      name: string;
      qualityStatus: EQualityStatus;
    };
  };
};

export type TChannelProfileMessagingLimitChangedNotification = TGenericNotification & {
  type: EWebNotificationType.ChannelProfileMessagingLimitChanged;
  meta: {
    whatsAppProfileMessagingLimitChanged: {
      name: string;
      messagingLimit: EMessagingLimit;
    };
  };
};

export type TChannelProfileQualityScoreChangedNotification = TGenericNotification & {
  type: EWebNotificationType.ChannelProfileQualityScoreChanged;
  meta: {
    whatsAppProfileQualityScoreChanged: {
      name: string;
      qualityScore: EQualityScore;
    };
  };
};

export type TChannelProfileViolationNotification = TGenericNotification & {
  type: EWebNotificationType.ChannelProfileWABAViolation;
  meta: {
    whatsAppESProfileWABAViolation: {
      wabaId: number;
      violationType: string;
      whatsappEsProfileInternalNames: string[];
    };
  };
};

export type TChannelProfileRestrictionNotification = TGenericNotification & {
  type: EWebNotificationType.ChannelProfileWABARestriction;
  meta: {
    whatsAppESProfileWABARestriction: {
      wabaId: number;
      expirationDate: string;
      whatsappEsProfileInternalNames: string[];
    };
  };
};

export type TChannelProfileDisableNotification = TGenericNotification & {
  type: EWebNotificationType.ChannelProfileWABADisable;
  meta: {
    whatsAppESProfileWABADisable: {
      wabaId: number;
      whatsappEsProfileInternalNames: string[];
    };
  };
};

export type TChannelProfileReinstateNotification = TGenericNotification & {
  type: EWebNotificationType.ChannelProfileWABAReinstate;
  meta: {
    whatsAppESProfileWABAReinstate: {
      wabaId: number;
      whatsappEsProfileInternalNames: string[];
    };
  };
};

export type TMessageMatcherCategoryChangedNotification = TGenericNotification & {
  type: EWebNotificationType.MessageMatcherCategoryChanged;
  meta: {
    messageMatcherCategoryChanged: {
      name: string;
      newCategory: EWhatsAppCategory;
    };
  };
};

export type TMessageMatcherStatusChangedNotification = TGenericNotification & {
  type: EWebNotificationType.MessageMatcherStatusChanged;
  meta: {
    messageMatcher: {
      name: string;
      approveStatus: EApproveStatus;
    };
  };
};

export type TJivoNotification = TGenericNotification & {
  type: EWebNotificationType.JivoDisabled | EWebNotificationType.JivoEnabled;
  meta: {
    updateJivoIntegration: {
      login: string;
    };
  };
};

export type TAgreedChangeTariffRequestNotification = TGenericNotification & {
  type: EWebNotificationType.AgreedChangeTariffRequest;
  meta: {
    agreedChangeTariffRequest: {
      login: string;
      tariffName: string;
    };
  };
};

export type TNotAgreedChangeTariffRequestNotification = TGenericNotification & {
  type: EWebNotificationType.NotAgreedChangeTariffRequest;
  meta: {
    notAgreedChangeTariffRequest: {
      login: string;
      tariffName: string;
    };
  };
};

export type TTenantTariffAcceptedNotification = TGenericNotification & {
  type: EWebNotificationType.TenantTariffAccepted;
  meta: {
    acceptTenantTariff: {
      login: string;
    };
  };
};

export type TPaymentDetailsUpdatedNotification = TGenericNotification & {
  type: EWebNotificationType.PaymentDetailsUpdated;
  meta: {
    updatePaymentDetails: {
      login: string;
    };
  };
};

export type TCompanyDetailsUpdatedNotification = TGenericNotification & {
  type: EWebNotificationType.CompanyDetailsUpdated;
  meta: {
    updateCompanyDetails: {
      login: string;
    };
  };
};

export type TLicenseExpiringNotification = TGenericNotification & {
  type: EWebNotificationType.LicenseExpiring;
  meta: {
    licenseExpiring: {
      expireAt: string;
    };
  };
};

export type TSubscribersUploadNotification = TGenericNotification & {
  type:
    | EWebNotificationType.SubscribersUploadFailed
    | EWebNotificationType.SubscribersUploadLoading
    | EWebNotificationType.SubscribersUploadPartial
    | EWebNotificationType.SubscribersUploadSuccess;
  meta: {
    subscriberUpload: {
      uploadId: number;
      fileName?: string;
      reportUrl?: string;
      errorReportFileName?: string;
      uploadedInTotal?: number;
      uploadedWithErrors?: number;
    };
  };
};

export type TBroadcastNotification = TGenericNotification & {
  type: EWebNotificationType.BroadcastCancelled | EWebNotificationType.BroadcastCompleted;
  meta: {
    broadcast: {
      id: number;
      name: string;
    };
  };
};

export enum EAutopaymentStatus {
  AUTO_REPLENISHMENT_ENABLED = 'AUTO_REPLENISHMENT_ENABLED',
  AUTO_REPLENISHMENT_DISABLED = 'AUTO_REPLENISHMENT_DISABLED',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
}

export type TStripeAutopaymentNotification = TGenericNotification & {
  type: EWebNotificationType.StripeAutopayment;
  meta: {
    checkoutData: TAutopaymentError & {
      status: EAutopaymentStatus;
      hostedReceiptUrl?: string;
    };
  };
};

export enum ESystemNotificationType {
  UpdatePlanned = 'UpdatePlanned',
  UpdateRunning = 'UpdateRunning',
  MaintenanceRunning = 'MaintenanceRunning',
}

export type TReleaseNotification = TGenericSystemNotification & {
  type: ESystemNotificationType.UpdatePlanned | ESystemNotificationType.UpdateRunning;
  meta: {
    data: {
      startAt: string;
      endAt: string;
    };
  };
};

export type TSystemNotification =
  | TReleaseNotification
  | (TGenericSystemNotification & {
      type: ESystemNotificationType.MaintenanceRunning;
    });

export type TNotification =
  | TChannelProfileQualityStatusChangedNotification
  | TChannelProfileMessagingLimitChangedNotification
  | TChannelProfileQualityScoreChangedNotification
  | TChannelProfileViolationNotification
  | TChannelProfileRestrictionNotification
  | TChannelProfileDisableNotification
  | TChannelProfileReinstateNotification
  | TMessageMatcherCategoryChangedNotification
  | TMessageMatcherStatusChangedNotification
  | TJivoNotification
  | TAgreedChangeTariffRequestNotification
  | TPaymentDetailsUpdatedNotification
  | TNotAgreedChangeTariffRequestNotification
  | TTenantTariffAcceptedNotification
  | TCompanyDetailsUpdatedNotification
  | TLicenseExpiringNotification
  | TSubscribersUploadNotification
  | TBroadcastNotification
  | TStripeAutopaymentNotification
  | (TGenericNotification & {
      type:
        | EWebNotificationType.CallbackUpdated
        | EWebNotificationType.ApikeyUserUpdated
        | EWebNotificationType.InvoicePaid;
    });

export type TNotifications = {
  webNotifications: TNotification[];
  systemNotifications: TSystemNotification[];
};

export type TFilters = {
  levels: Record<ELevel, boolean>;
  categories: Record<EStatus, boolean>;
  period: TPeriodPickerValue;
};
