import React from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes, useMatch} from 'react-router-dom';

import {useOnMount} from '@edna/hooks';
import {or} from '@edna/utils';

import {Header} from 'src/components';
import IconTabs from 'src/components/IconTabs';
import CompanyCallback from 'src/containers/CompanyCallback';
import {EResellerIds, useReseller} from 'src/containers/User';
import {useRedirect} from 'src/hooks';
import {DocumentationIcon, ExperimentIcon, PuzzleIcon, TechSupportIcon} from 'src/icons';
import PATHS from 'src/paths';

import Documentation from './Documentation';
import Modules from './Modules';
import Testing from './Testing';
import * as S from './style';

export enum EIntegrationTabs {
  DOCUMENTATION = 'documentation',
  TESTING = 'testing',
  SETTINGS = 'settings',
  MODULES = 'modules',
}

export enum EIntegrationTabsBlr {
  DOCUMENTATION = 'documentation',
  SETTINGS = 'settings',
}

const tabIconHash = {
  [EIntegrationTabs.DOCUMENTATION]: DocumentationIcon,
  [EIntegrationTabs.TESTING]: ExperimentIcon,
  [EIntegrationTabs.SETTINGS]: TechSupportIcon,
  [EIntegrationTabs.MODULES]: PuzzleIcon,
};

const Integration = React.memo(() => {
  const {t} = useTranslation();
  const redirect = useRedirect();
  const match = useMatch(PATHS.INTEGRATION_TAB);
  const routeTab = match?.params?.tab;
  const isBLRReseller = useReseller(
    or<EResellerIds>(EResellerIds.BLR_FIRST_ID, EResellerIds.BLR_SECOND_ID),
  );

  useOnMount(() => {
    if (!Object.values(EIntegrationTabs).includes(routeTab as EIntegrationTabs)) {
      redirect(PATHS.HOME);
    }
  });

  const tabLabelHash = React.useMemo(
    () => ({
      [EIntegrationTabs.DOCUMENTATION]: t(`Integration:tabs.${EIntegrationTabs.DOCUMENTATION}`),
      [EIntegrationTabs.TESTING]: t(`Integration:tabs.${EIntegrationTabs.TESTING}`),
      [EIntegrationTabs.SETTINGS]: t(`Integration:tabs.${EIntegrationTabs.SETTINGS}`),
      [EIntegrationTabs.MODULES]: t(`Integration:tabs.${EIntegrationTabs.MODULES}`),
    }),
    [t],
  );

  return (
    <>
      <Header />
      <S.Section>
        <IconTabs
          tabs={Object.values(isBLRReseller ? EIntegrationTabsBlr : EIntegrationTabs)}
          tabIconHash={tabIconHash}
          tabLabelHash={tabLabelHash}
          path={PATHS.INTEGRATION_TAB}
        />
        <Routes>
          <Route path={EIntegrationTabs.DOCUMENTATION} element={<Documentation />} />
          <Route path={EIntegrationTabs.TESTING} element={<Testing />} />
          <Route path={EIntegrationTabs.SETTINGS} element={<CompanyCallback />} />
          <Route path={EIntegrationTabs.MODULES} element={<Modules />} />
        </Routes>
      </S.Section>
    </>
  );
});

Integration.displayName = 'Integration';

export default Integration;
