import {EWabaStatus} from '../Channels/definitions';
import {EFormSteps, EPublicStatus, EStatus, LOGO_SIZE} from './definitions';

const activeStatus = {
  title: 'Работает исправно',
  messageTitle: 'Канал работает исправно',
  messageContent:
    'Ваш канал зарегистрирован. Теперь вы можете использовать его для общения с вашими клиентами. ' +
    'Чтобы увеличить суточный лимит канала до 1000 сообщений и отображать название аккаунта в чатах клиентов, выполните одно из рекомендуемых условий: ',
  reason1: 'Отправьте 1000 шаблонных сообщений в течение 30 дней.',
  reason2: 'Пройдите верификацию бизнеса в Meta. ',
  link: 'Инструкция по прохождению верификации.',
};

export default {
  steps: {
    [EFormSteps.SETTINGS]: 'Условия регистрации и настройка',
    [EFormSteps.VIEW]: 'Представление аккаунта',
  },
  clarifyForm: {
    title: 'Данные канала {{name}}',
    confirmTitle: 'Сохранить изменения?',
    confirmMessage: 'После сохранения изменить тариф и внутреннее название канала невозможно',
    cancelTitle: 'Отменить изменения?',
    cancelMessage:
      'Сохраните выбранный тариф, чтобы канал клиента перешел в статус «Работает исправно»',
  },
  editForm: {
    name: 'Внутреннее название канала',
    nameHint: 'Это название будет отображаться в списке каналов в edna Pulse',
    tariff: 'Тариф для канала',
    address: 'Адрес компании',
    email: 'Email компании',
    status: 'Статус аккаунта WhatsApp',
    logo: 'Логотип компании',
    logoHint: `Логотип вашей компании в формате JPG или JPEG размером не менее ${LOGO_SIZE}×${LOGO_SIZE}px. После регистрации канала вы можете изменить это поле через службу поддержки edna.`,
    baseHint:
      'После регистрации канала этот параметр можно поменять через службу технической поддержки edna.',
    settingsBlock: 'Настройки канала',
    viewBlock: 'Представление аккаунта',
    viewBlockHint:
      'Ваши клиенты будут видеть эти данные при просмотре профиля этого аккаунта WhatsApp.',
    phone: 'Номер телефона, на который зарегистрирован канал',
    phoneHint: 'Этот номер телефона указан при регистрации вашего WhatsApp Business Account.',
    facebookHint:
      'Чтобы зарегистрировать канал в WhatsApp Business Account войдите в учетную запись Facebook и следуйте предложенным шагам.',
  },
  techPartner: {
    code: 'Ссылка для регистрации',
    codeHint:
      'Скопируйте и отправьте эту ссылку клиенту, чтобы он завершил регистрацию канала WhatsApp',
  },
  regenerateCodeModal: {
    title: 'Обновить ссылку для регистрации?',
    subTitle: 'После обновления текущая ссылка станет недействительной и будет создана новая',
  },
  publicForm: {
    title:
      'На этой странице вы можете зарегистрировать канал WhatsApp. Информацию о регистрации получит ваш провайдер {{partnerName}}',
    helpTitle: 'Обязательные условия для регистрации:',
    helpSteps: [
      'У вас есть личный аккаунт Facebook.',
      'У вас есть номер телефона для регистрации аккаунта WhatsApp. Номер не должен быть связан с другими аккаунтами WhatsApp.',
      'Вы можете получить SMS-сообщение или принять международный звонок на указанный номер телефона, чтобы подтвердить его.',
    ],
    successTitle: 'Канал отправлен на регистрацию',
    successHint:
      'Чтобы узнать статус регистрации, обратитесь к вашему провайдеру<br/><0>{{partnerName}}</0>',
    errorTitle: 'По этой ссылке регистрация канала WhatsApp недоступна:<br/><0>{{error}}</0>',
    errorHint:
      'Для получения новой ссылки обратитесь к вашему провайдеру<br/><0>{{partnerName}}</0>',
    errors: {
      [EPublicStatus.ERROR_USED_CODE]: 'по этой ссылке канал уже зарегистрирован',
      [EPublicStatus.ERROR_INCORRECT_TARIFF]: 'для канала указаны неверные условия подключения',
      [EPublicStatus.ERROR_OLD_CODE]: 'срок действия ссылки истек',
      unknown: 'такой страницы не существует',
    },
    facebookTerms:
      'Нажимая на Продолжить с Facebook, вы соглашаетесь с <0>условиями предоставления услуги</0>',
  },
  adblockStatus: {
    info: 'У вас выключены расширения или настройки в браузере для блокировки всплывающих окон.',
    adblockDetected:
      'На вашем устройстве включено расширение типа Adblock для блокировки всплывающих окон. Чтобы продолжить, отключите расширение.',
    popupsDetected:
      'Возможно, в вашем браузере включена блокировка всплывающих окон или расширение типа Adblock. Чтобы продолжить, измените настройки браузера или отключите расширение.',
    notDetected:
      'Мы не нашли включенных блокировок. Если окно Facebook не откроется, проверьте расширения и настройки в браузере.',
    retry: 'Перепроверить',
  },
  facebookAvailability: {
    info: 'На вашем устройстве открывается сайт Facebook.',
    error:
      'На вашем устройстве не открывается сайт Facebook.\nЧтобы продолжить, установите соединение с сайтом',
    loading: 'Установка соединения с сайтом',
    retry: 'Перепроверить',
  },
  imageErrors: {
    invalidLogoFormat: 'Неверный формат логотипа',
  },
  status: {
    [EStatus.UI_NEED_TARIFF_TEMPLATE_ID]: {
      title: 'Требует внимания',
      messageTitle: 'Выберите тариф',
      messageContent:
        'Ваш клиент {{name}} отправил канал на регистрацию. Чтобы завершить регистрацию, выберите тариф канала. До сохранения тарифа вы можете изменить внутреннее название канала.',
    },
    [EStatus.DRAFT]: {title: 'Черновик'},
    [EStatus.NEW]: {title: 'Новый'},
    [EStatus.DELETED]: {title: 'Отключен'},
    [EStatus.DONE_BUSINESS_VERIFICATION]: activeStatus,
    [EStatus.BUSINESS_VERIFICATION_EXPIRED]: activeStatus,
    [EStatus.DONE_FINISH_REGISTER]: {
      title: 'Работает исправно',
      messageTitle: 'Канал работает исправно',
      messageContent:
        'Вы прошли верификацию бизнеса в Meta для этого WhatsApp-аккаунта, и ваш канал зарегистрирован. Теперь вам доступны увеличенные суточные лимиты на отправку шаблонных сообщений и отображение названия аккаунта в чатах клиентов.',
    },
    [EStatus.SENT_TO_PLATFORM]: {
      title: 'Регистрация',
      messageTitle: 'Канал на регистрации',
      messageContent: 'Ваш канал проходит регистрацию в Facebook и уже скоро станет доступен',
    },
    [EStatus.CANCELLED]: {
      title: 'Отключен',
      messageTitle: 'Канал отключен',
    },

    [EStatus.DISABLED]: {
      title: 'Отключен',
      messageTitle: 'Канал отключен',
    },
  },
  wabaStatus: {
    [EWabaStatus.ACCOUNT_RESTRICTION]: {
      messageTitle: 'Канал ограничен (WABA)',
      messageContent:
        'Бизнес-аккаунт WhatsApp {{wabaId}}, в которому относится этот канал, ограничен из-за нарушения правил или политики использования WhatsApp. {{reason}}<b>Отправка исходящих сообщений невозможна до {{expirationDate}}</b>. Канал доступен только для приема входящих сообщений.' +
        '<br/><br/>Чтобы подать апелляцию, <0>следуйте рекомендациям</0>.',
      wabaRestrictionReason: 'Причина — {{violationType}}. ',
    },
    [EWabaStatus.DISABLED]: {
      messageTitle: 'Канал отключен',
      messageContent:
        'Бизнес-аккаунт WhatsApp {{wabaId}}, в которому относится этот канал, отключен из-за нарушения правил или политики использования WhatsApp. Работа канала остановлена.' +
        '<br/><br/>Чтобы подать апелляцию, <0>следуйте рекомендациям</0>.',
    },
  },
};
