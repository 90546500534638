import {useParams} from 'react-router-dom';

import {skipToken} from '@reduxjs/toolkit/query';

import {useGetSupportClientQuery} from 'src/containers/SupportClients/api';
import {
  EFeatureFlag,
  EPaymentType,
  EResellerIds,
  useFeatureFlag,
  useGetCurrentUserQuery,
  useGetTenantDataQuery,
} from 'src/containers/User';

export const useAutopaymentEnabled = () => {
  const {id: supportTenantId} = useParams<'id'>();
  const [hasFeatureFlag] = useFeatureFlag(EFeatureFlag.STRIPE_AUTOPAYMENT);

  const {data: supportClient} = useGetSupportClientQuery(supportTenantId ?? skipToken);
  const {data: tenantData} = useGetTenantDataQuery();
  const {data: user} = useGetCurrentUserQuery();

  const isPrePaymentType = supportTenantId
    ? supportClient?.paymentType === EPaymentType.PREPAYMENT
    : tenantData?.paymentType === EPaymentType.PREPAYMENT;
  const resellerId = supportTenantId ? supportClient?.resellerId : user?.resellerId;

  return hasFeatureFlag || (isPrePaymentType && resellerId === EResellerIds.MFM_ID);
};
