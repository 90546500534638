import React from 'react';

import {useTheme} from 'styled-components';

import {useEvent} from '@edna/hooks';
import {delay, events} from '@edna/utils';

import {EEvent} from 'src/constants';

const useNotificationsSize = (parentRef: React.RefObject<HTMLDivElement>) => {
  const [style, setStyle] = React.useState({
    left: 'auto',
    width: '100%',
  });

  const {animation} = useTheme();

  const handleSize = React.useCallback(() => {
    if (!parentRef.current) {
      return;
    }

    const styles = window.getComputedStyle(parentRef.current);
    const paddingRight = parseFloat(styles.getPropertyValue('padding-right'));
    const paddingLeft = parseFloat(styles.getPropertyValue('padding-left'));

    setStyle({
      left: `${parentRef.current.offsetLeft + paddingLeft}px`,
      width: `${parentRef.current.clientWidth - paddingRight - paddingLeft}px`,
    });
  }, [parentRef.current, setStyle]);

  const handleSizeAfterAnimate = async () => {
    await delay(animation.duration);
    handleSize();
  };

  React.useEffect(() => {
    handleSize();
    events.on(EEvent.MENU_INTERACTION, handleSizeAfterAnimate);

    return () => {
      events.off(EEvent.MENU_INTERACTION, handleSizeAfterAnimate);
    };
  }, []);

  useEvent('resize', handleSize, {target: window});

  return {width: style.width, left: style.left};
};

export {useNotificationsSize};
