import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {skipToken} from '@reduxjs/toolkit/query';

import {ActionButton, Button} from '@edna/components';
import {Icon, Stack} from '@edna/components/primitives';
import CloseIcon from '@edna/icons/close.svg';
import EditIcon from '@edna/icons/edit.svg';
import {ECurrency, ECurrencySymbol, and} from '@edna/utils';

import {Message} from 'src/components';
import {
  ButtonContent,
  InlineLink,
  InlineLinkButton,
  SecondaryText,
  Text,
} from 'src/components/primitives';
import {useGetSupportClientQuery} from 'src/containers/SupportClients/api';
import {CoinsIcon} from 'src/icons';
import {parseISO} from 'src/utils';

import {EPermission, useGetTenantDataQuery, usePermission} from '../User';
import {showAutopaymentSettingsModal} from './AutopaymentSettingsModal';
import {
  showAutopaymentSupportModal,
  showChangeAutopaymentCreditsModal,
  showChargeAutopaymentModal,
  showOffAutopaymentModal,
} from './Modals';
import {useGetAutopaymentInfoQuery, useRestoreAutopaymentMutation} from './api';
import {
  EAutopaymentError,
  EAutopaymentStatus,
  TAutopayment,
  TAutopaymentError,
} from './definitions';
import * as S from './style';
import {useAutopaymentRecommendedThreshold} from './useAutopaymentRecommendedThreshold';

const getFormattedDate = (date: string) => parseISO(date, 'dd.MM.yyyy');

const Error = React.memo<{error: TAutopaymentError}>(({error}) => {
  const {t} = useTranslation();

  if (error.declineCode === EAutopaymentError.GENERIC_DECLINE) {
    return null;
  }

  if (error.declineCode === EAutopaymentError.INSUFFICIENT_FUNDS) {
    return (
      <Message type="Error" fontSize="S" noMargin>
        <Trans
          i18nKey={`Autopayment:errors.${error.declineCode}`}
          values={{
            date: getFormattedDate(error.occurredAt),
            nextDate: getFormattedDate(error.nextAttemptAt),
            leftDays: error.attemptsLeft,
            leftDays1: t('Common:time.days', {count: error.attemptsLeft}),
            leftDays2: t('Common:time_alternate.days', {count: error.attemptsLeft}),
          }}
        />
      </Message>
    );
  }

  return (
    <Message type="Error" fontSize="S" noMargin>
      <Trans
        i18nKey={`Autopayment:errors.${error.declineCode}`}
        values={{date: getFormattedDate(error.occurredAt)}}
      >
        <InlineLink href={error.hostedInvoiceUrl} />
      </Trans>
    </Message>
  );
});

const SupportActions = React.memo(({isAutopaymentPaused}: {isAutopaymentPaused: boolean}) => {
  const {id: supportTenantId} = useParams<'id'>();
  const {t} = useTranslation();
  const hasPermissions = usePermission(
    and<EPermission>('PERMISSION_TENANT_BALANCE_ADD', 'PERMISSION_TENANT_BALANCE_SUBTRACT'),
  );

  const [restoreAutopayment] = useRestoreAutopaymentMutation();

  const handleRestore = React.useCallback(
    () => restoreAutopayment(supportTenantId),
    [restoreAutopayment, supportTenantId],
  );

  if (isAutopaymentPaused) {
    return hasPermissions ? (
      <Button onClick={handleRestore} size="S">
        <ButtonContent>
          <Icon as={CoinsIcon} size="12px" />
          {t('Autopayment:actions.restore')}
        </ButtonContent>
      </Button>
    ) : null;
  }

  return (
    <Stack direction="ROW" size="2">
      <Button onClick={() => showAutopaymentSupportModal(isAutopaymentPaused)} size="S">
        <ButtonContent>
          <Icon as={CloseIcon} size="12px" />
          {t(`Autopayment:actions.${isAutopaymentPaused ? 'connect' : 'off'}`)}
        </ButtonContent>
      </Button>
      <Button onClick={showChargeAutopaymentModal} size="S">
        {t(`Autopayment:actions.chargeAutopayment`)}
      </Button>
    </Stack>
  );
});

const ClientActions = React.memo<{isAutopaymentPaused: boolean}>(({isAutopaymentPaused}) => {
  const {t} = useTranslation();

  const [restoreAutopayment] = useRestoreAutopaymentMutation();

  const actionsOptions = React.useMemo(
    () => [
      {
        id: 'changeSettings',
        content: t('Autopayment:actions.changeSettings'),
        handler: () => showAutopaymentSettingsModal(),
      },
      {
        id: 'changeCardCredits',
        content: t('Autopayment:actions.changeCardCredits'),
        handler: showChangeAutopaymentCreditsModal,
      },
      {
        id: 'off',
        content: t('Autopayment:actions.off'),
        handler: showOffAutopaymentModal,
      },
    ],
    [t],
  );

  const handleRestore = React.useCallback(() => restoreAutopayment(), [restoreAutopayment]);

  if (isAutopaymentPaused) {
    return (
      <Button onClick={handleRestore} size="S">
        <ButtonContent>
          <Icon as={CoinsIcon} size="12px" />
          {t('Autopayment:actions.restore')}
        </ButtonContent>
      </Button>
    );
  }

  return (
    <ActionButton options={actionsOptions} size="XS" appearance="default">
      <EditIcon />
      {t('Autopayment:actions.changeSettings')}
    </ActionButton>
  );
});

const AutopaymentInfo = React.memo<{info: TAutopayment}>(({info}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const {data} = useGetTenantDataQuery();

  const {data: supportClient} = useGetSupportClientQuery(supportTenantId ?? skipToken);
  const recommendedThreshold = useAutopaymentRecommendedThreshold();

  const currency =
    (supportTenantId ? supportClient?.defaultCurrency : data?.defaultCurrency) ?? ECurrency.USD;

  const isAutopaymentPaused = info.customerStatus === EAutopaymentStatus.DISABLED;

  const handleChangeSettings = React.useCallback(() => showAutopaymentSettingsModal(), []);

  return (
    <Stack size="4">
      <Stack size="2" direction="ROW" align="center">
        <Text weight={500} color={isAutopaymentPaused ? 'red500' : 'grey900'}>
          <S.SecondaryCoinsIcon />
          {t(`Autopayment:status.${isAutopaymentPaused ? 'paused' : 'connected'}`)}
        </Text>
        {!!supportTenantId && <SupportActions isAutopaymentPaused={isAutopaymentPaused} />}
        {!supportTenantId && <ClientActions isAutopaymentPaused={isAutopaymentPaused} />}
      </Stack>
      <S.NestedBlock>
        {info.threshold < recommendedThreshold && (
          <Message type="Warning" fontSize="S" noMargin>
            <Trans i18nKey="Autopayment:thresholdWarning">
              <InlineLinkButton onClick={handleChangeSettings} />
            </Trans>
          </Message>
        )}
        {!!info.paymentError && <Error error={info.paymentError} />}
        <SecondaryText size="S">
          <Trans
            i18nKey="Autopayment:info"
            values={{
              currency: ECurrencySymbol[currency],
              threshold: info.threshold,
              amount: info.amount,
            }}
          >
            <Text color="grey400" isInline size="S" />
            <Text isInline size="S" />
            <Text color="blue500" isInline size="S" />
          </Trans>
        </SecondaryText>
      </S.NestedBlock>
    </Stack>
  );
});

const Autopayment = React.memo(() => {
  const {id: supportTenantId} = useParams<'id'>();

  const {data: info} = useGetAutopaymentInfoQuery(supportTenantId);

  if (!info) {
    return null;
  }

  return <AutopaymentInfo info={info} />;
});

export {Autopayment};
