import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';

import QRCode from 'qrcode.react';

import {showModal} from '@edna/components';
import {copyToClipBoard} from '@edna/utils';

import GenerateNewApiKeyModal from 'src/containers/CompanyCallback/ApiKey/dialogs';
import {
  showAddCallbackUrlModal,
  showChangeCallbackUrlModal,
  showDeleteCallbackUrlModal,
} from 'src/containers/CompanyCallback/CallbackUrl/modals';
import {
  useGenerateApiKeyMutation,
  useGetAllCallbackUrlsQuery,
  useGetApiKeyQuery,
} from 'src/containers/CompanyCallback/api';
import {EUrlType} from 'src/containers/CompanyCallback/definitions';
import {getCallbackUrlByType} from 'src/containers/CompanyCallback/utils';
import {TMenuButtonOption} from 'src/containers/MenuButton';
import {RefreshIcon} from 'src/icons';
import PATHS from 'src/paths';
import {joiResolver} from 'src/utils/validator';

import {
  TPhoneData,
  useGetCascadeTemplateIdQuery,
  useGetTestPhoneForTestingQuery,
  useGetTestPhonesQuery,
  useSaveTestPhoneMutation,
} from '../api';
import Console from './Console';
import InputWithOptions from './InputWithOptions';
import * as S from './style';

const getTestWhatsAppLink = (phone: string) => `https://wa.me/${phone}?text=test`;

export const resolver = joiResolver((joi) =>
  joi.object({
    phone: joi.string().min(4),
  }),
);

const Testing = React.memo(() => {
  const {t} = useTranslation();

  const {data: callbackUrls = [], isLoading: isCallbackLoading} = useGetAllCallbackUrlsQuery();
  const {data: apiKeyData, isLoading: isApiKeyLoading} = useGetApiKeyQuery();
  const {data: testPhones = [], isLoading: isTestPhonesLoading} = useGetTestPhonesQuery();
  const {data: testPhoneForTesting} = useGetTestPhoneForTestingQuery();
  const {data: cascadeTemplateId} = useGetCascadeTemplateIdQuery();

  const [saveTestPhone] = useSaveTestPhoneMutation();
  const [generateApiKey] = useGenerateApiKeyMutation();

  const methods = useForm<TPhoneData>({
    mode: 'all',
    defaultValues: {phone: ''},
    resolver,
  });

  const handleCopy = React.useCallback(() => {
    if (apiKeyData?.apiKey) {
      copyToClipBoard(apiKeyData.apiKey);
    }
  }, [apiKeyData?.apiKey]);

  const testWhatsAppLink = React.useMemo(
    () => getTestWhatsAppLink(testPhoneForTesting ?? testPhones[0] ?? ''),
    [testPhoneForTesting, testPhones],
  );

  const handleGenerate = React.useCallback(() => generateApiKey(), []);
  const handleAddPhone = React.useCallback(
    (values) => {
      saveTestPhone(values);
      methods.reset();
    },
    [methods],
  );

  const showModalForChangeApiKey = React.useCallback(() => {
    showModal((closeModal) => (
      <GenerateNewApiKeyModal closeModal={closeModal} apiKeyData={apiKeyData} />
    ));
  }, [apiKeyData]);

  const modalEffects = React.useMemo(() => {
    const inboxCallback = getCallbackUrlByType(callbackUrls, EUrlType.IN_MESSAGE);
    const statusCallback = getCallbackUrlByType(callbackUrls, EUrlType.STATUS);

    return {
      showInboxChangeModal: () => showChangeCallbackUrlModal(inboxCallback),
      showInboxDeleteModal: () => showDeleteCallbackUrlModal(inboxCallback),
      showStatusesChangeModal: () => showChangeCallbackUrlModal(statusCallback),
      showStatusesDeleteModal: () => showDeleteCallbackUrlModal(statusCallback),
    };
  }, [callbackUrls]);

  const menuOptionsApikey = React.useMemo(() => {
    const result: TMenuButtonOption[] = [
      {
        type: 'COPY',
        onClick: handleCopy,
      },
    ];

    if (!apiKeyData?.locked) {
      result.push({
        type: 'GENERATE',
        onClick: showModalForChangeApiKey,
        icon: RefreshIcon,
        label: t('Integration:testing.generate'),
      });
    }

    return result;
  }, [handleCopy, apiKeyData?.locked, showModalForChangeApiKey, t]);

  const menuOptionsInboxUrl = React.useMemo(
    () => [
      {
        type: 'EDIT',
        onClick: modalEffects.showInboxChangeModal,
        label: t('Common:button.change'),
      },
      {
        type: 'DELETE',
        onClick: modalEffects.showInboxDeleteModal,
      },
    ],
    [t, modalEffects],
  );

  const menuOptionsStatusesUrl = React.useMemo(
    () => [
      {
        type: 'EDIT',
        onClick: modalEffects.showStatusesChangeModal,
        label: t('Common:button.change'),
      },
      {
        type: 'DELETE',
        onClick: modalEffects.showStatusesDeleteModal,
      },
    ],
    [t, modalEffects],
  );

  const apiKeyRow = React.useMemo(() => {
    if (isApiKeyLoading) {
      return (
        <S.Nested>
          <S.SkeletonItem />
        </S.Nested>
      );
    }

    if (apiKeyData?.apiKey) {
      return (
        <>
          <S.NumberRow number={1}>{t('Integration:testing.row1.apiKey')}</S.NumberRow>
          <InputWithOptions value={apiKeyData?.apiKey} options={menuOptionsApikey} />
        </>
      );
    }

    return (
      <>
        <S.NumberRow number={1}>{t('Integration:testing.row1.noApiKey')}</S.NumberRow>
        <S.Nested>
          <S.Button appearance="secondary" onClick={handleGenerate}>
            {t('Integration:testing.generate')}
          </S.Button>
        </S.Nested>
      </>
    );
  }, [isApiKeyLoading, apiKeyData?.apiKey, t, menuOptionsApikey, handleGenerate]);

  const inboxRow = React.useMemo(() => {
    const inboxCallback = getCallbackUrlByType(callbackUrls, EUrlType.IN_MESSAGE);

    if (isCallbackLoading) {
      return (
        <S.Nested>
          <S.SkeletonItem />
        </S.Nested>
      );
    }

    if (inboxCallback.url) {
      return (
        <>
          <S.NumberRow number={2}>{t('Integration:testing.row2.inbox')}</S.NumberRow>
          <InputWithOptions value={inboxCallback.url} options={menuOptionsInboxUrl} />
        </>
      );
    }

    const showInboxAddModal = () => showAddCallbackUrlModal(inboxCallback.type);

    return (
      <>
        <S.NumberRow number={2}>{t('Integration:testing.row2.noInbox')}</S.NumberRow>
        <S.Nested>
          <S.Button appearance="secondary" onClick={showInboxAddModal}>
            {t('Integration:testing.addLink')}
          </S.Button>
        </S.Nested>
      </>
    );
  }, [callbackUrls, isCallbackLoading, menuOptionsInboxUrl, t]);

  const statusesRow = React.useMemo(() => {
    const statusCallback = getCallbackUrlByType(callbackUrls, EUrlType.STATUS);

    if (isCallbackLoading) {
      return (
        <S.Nested>
          <S.SkeletonItem />
        </S.Nested>
      );
    }

    if (statusCallback.url) {
      return (
        <>
          <S.NumberRow number={3}>{t('Integration:testing.row3.statuses')}</S.NumberRow>
          <InputWithOptions value={statusCallback.url} options={menuOptionsStatusesUrl} />
        </>
      );
    }

    const showStatusesAddModal = () => showAddCallbackUrlModal(statusCallback.type);

    return (
      <>
        <S.NumberRow number={3}>{t('Integration:testing.row3.noStatuses')}</S.NumberRow>
        <S.Nested>
          <S.Button appearance="secondary" onClick={showStatusesAddModal}>
            {t('Integration:testing.addLink')}
          </S.Button>
        </S.Nested>
      </>
    );
  }, [isCallbackLoading, menuOptionsStatusesUrl, callbackUrls, t]);

  return (
    <>
      {apiKeyRow}
      {inboxRow}
      {statusesRow}
      <S.NumberRow number={4}>{t('Integration:testing.row4.text')} </S.NumberRow>
      <S.Nested>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleAddPhone)}>
            {!!testPhones.length && (
              <S.PhonesContainer>
                {testPhones.map((item) => (
                  <S.PhoneChip key={item}>{item}</S.PhoneChip>
                ))}
              </S.PhonesContainer>
            )}
            <S.Label>{t('Integration:testing.row4.hint')}</S.Label>
            <S.Row>
              <S.NumberInputField name="phone" />
              <S.LoadingButton
                type="submit"
                disabled={!methods.formState.isDirty || !methods.formState.isValid}
                loading={isTestPhonesLoading}
                data-selector="Integration:testing.addPhone"
              >
                {t('Integration:testing.addPhone')}
              </S.LoadingButton>
            </S.Row>
          </form>
        </FormProvider>
      </S.Nested>
      <S.NumberRow number={5}>
        {t('Integration:testing.row5.text')}
        <S.Link href={testWhatsAppLink}>{testWhatsAppLink}</S.Link>
      </S.NumberRow>
      <S.QRContainer>
        <S.QRSubtitle>{t('Integration:testing.row5.subtitle')}</S.QRSubtitle>
        <QRCode value={testWhatsAppLink} size={150} />
      </S.QRContainer>
      <S.Nested>
        <S.QRHint>
          <Trans i18nKey="Integration:testing.row5.hint">
            <S.RouterLink to={PATHS.ANALYTICS_MESSAGES} />
          </Trans>
        </S.QRHint>
      </S.Nested>
      <S.NumberRow number={6}>{t('Integration:testing.row6.text')}</S.NumberRow>
      <Console
        apiKeyData={apiKeyData}
        cascadeId={cascadeTemplateId}
        testPhone={testPhoneForTesting}
      />
    </>
  );
});

Testing.displayName = 'Testing';

export default Testing;
