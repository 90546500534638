import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {Button, showModal} from '@edna/components';
import {Stack} from '@edna/components/primitives';
import {copyToClipBoard} from '@edna/utils';

import {BigText, Link, SecondaryText} from 'src/components/primitives';
import {EPermission, usePermission} from 'src/containers/User';
import {useEdnaLinks} from 'src/hooks';

import {useGenerateApiKeyMutation} from '../api';
import {TApiKeyData} from '../definitions';
import * as SS from '../style';
import GenerateNewApiKeyModal from './dialogs';

type TProps = {
  apiKeyData?: TApiKeyData;
};

const ApiKey = React.memo<TProps>(({apiKeyData}) => {
  const {t} = useTranslation();
  const {id: supportTenantId} = useParams<'id'>();

  const hasWritePermission = usePermission(EPermission.PERMISSION_WRITE);
  const hasTenantApikeyWritePermission = usePermission(EPermission.PERMISSION_TENANT_APIKEY_WRITE);
  const canChangeApiKey =
    !supportTenantId || (hasWritePermission && hasTenantApikeyWritePermission);

  const [generateApiKey] = useGenerateApiKeyMutation();

  const links = useEdnaLinks();

  const disabled = !!apiKeyData?.locked || !canChangeApiKey;
  const showModalForChangeApiKey = React.useCallback(() => {
    if (!apiKeyData) {
      return;
    }

    showModal((closeModal) => (
      <GenerateNewApiKeyModal closeModal={closeModal} apiKeyData={apiKeyData} />
    ));
  }, [apiKeyData]);

  const handleGenerateKey = React.useCallback(() => generateApiKey(), []);

  return (
    <SS.Content>
      <BigText>{t('CompanyCallback:yourAPIKey')}</BigText>
      {apiKeyData && (
        <Stack size="2" direction="ROW">
          <BigText>{`${apiKeyData.apiKey.slice(0, 6)}...${apiKeyData.apiKey.slice(-5)}`}</BigText>
          <SS.CopyIcon onClick={() => copyToClipBoard(apiKeyData.apiKey)} />
        </Stack>
      )}
      <SS.CenterRow>
        <SecondaryText bottomIndent="4">
          <Trans i18nKey="CompanyCallback:APIKeyDescription">
            <Link href={links.PULSE_API} />
          </Trans>
        </SecondaryText>
        {apiKeyData && !supportTenantId && (
          <Button
            onClick={showModalForChangeApiKey}
            appearance="secondary"
            disabled={disabled}
            data-selector="CompanyCallback:generateNewAPIKey"
          >
            {t('CompanyCallback:generateNewAPIKey')}
          </Button>
        )}
        {!apiKeyData && !supportTenantId && (
          <Button
            onClick={handleGenerateKey}
            appearance="secondary"
            disabled={disabled}
            data-selector="CompanyCallback:generateAPIKey"
          >
            {t('CompanyCallback:generateAPIKey')}
          </Button>
        )}
      </SS.CenterRow>
    </SS.Content>
  );
});

ApiKey.displayName = 'ApiKey';

export default ApiKey;
