import {EChannelType, TItemId} from 'src/constants';
import {enumToBooleanHash} from 'src/utils';

import {selectFilters, setFiltersAction} from './slice';

export enum EStepDecisionType {
  TTL = 'TTL',
}

export type TDecision = {
  type: EStepDecisionType.TTL;
  hours: number;
  minutes: number;
  seconds: number;
};

export type TCascadeStage = {
  uuid: string;
  decision?: Partial<TDecision>;
  subjectId?: number;
};

export enum EStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export const CASCADE_STATUSES = Object.values(EStatus);

export type TStatus = keyof typeof EStatus;

export type TListCascade = {
  id: TItemId;
  name: string;
  status: TStatus;
  channelTypes: EChannelType[];
};

export type TCascade = {
  id: TItemId;
  name: string;
  status: TStatus;
  stages: TCascadeStage[];
};

export type TStageEditForm = {
  uuid?: string;
  decision?: {
    type: EStepDecisionType.TTL;
    hours?: number | null;
    minutes?: number | null;
    seconds?: number | null;
  } | null;
  subjectId?: number | null;
};

export type TFormData = Omit<Partial<TCascade>, 'stages'> & {
  stages: TStageEditForm[];
};

export const DEFAULT_STAGE: TStageEditForm = {
  decision: null,
  subjectId: null,
};

export const DEFAULT_INITIAL_VALUES: TFormData = {
  name: '',
  status: EStatus.ACTIVE,
  stages: [DEFAULT_STAGE],
};

export const TTL_CHANNELS = [
  EChannelType.WHATSAPP,
  EChannelType.VIBER,
  EChannelType.VK_NOTIFY,
  EChannelType.OK_NOTIFY,
  EChannelType.PUSH,
  EChannelType.SMS,
];

export type TFilters = {
  filter?: string;
  subjectIds?: number[];
  statuses?: Partial<Record<EStatus, boolean>>;
  channelTypes?: Record<EChannelType, boolean>;
};

export const LIST_CONFIG = {
  filtersConfig: {
    defaultFilters: {
      filter: '',
      subjectIds: [],
      statuses: enumToBooleanHash(EStatus),
      channelTypes: enumToBooleanHash(EChannelType),
    },
    action: setFiltersAction,
    selector: selectFilters,
  },
};
