import React from 'react';

import {storage} from '@edna/utils';

import {EStorageKeys} from 'src/constants';

type TNewsState = Record<number, {isOpen: boolean}>;

export const useNewsState = (id: number) => {
  const [data, setData] = React.useState<TNewsState>(() => {
    const storageData = (storage.get<TNewsState>(EStorageKeys.NEWS) as TNewsState) ?? {
      [id]: {isOpen: true},
    };

    if (!storageData[id]) {
      return {...storageData, [id]: {isOpen: true}};
    }

    return storageData;
  });

  const handleClose = () => {
    setData({...data, [id]: {isOpen: false}});
  };

  React.useEffect(() => {
    storage.set(EStorageKeys.NEWS, data);
  }, [data]);

  return {isNewsOpen: !!data?.[id]?.isOpen, closeNews: handleClose};
};
